import { configureStore } from '@reduxjs/toolkit'
import documentReducer from './reducers/documents';
import userReducer from './reducers/user';

export default configureStore({
  reducer: {
    documents: documentReducer,
    user: userReducer
  }
});
