import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DesktopMiniMap from './DesktopMiniMap';
import { debounce } from '../services/utils';
import { getXCoordinateFromEvent, getYCoordinateFromEvent } from '../services/eventHandlers';

import { FileOutlined, FileFilled } from '@ant-design/icons';
import { DocumentIcon, DocumentLabel } from './DocumentIcon';

import { createDocumentFromCoords } from '../DocumentRepository';

import { docCreated, docMoved, docsMoved } from '../reducers/documents';
import MiniMap from '../components/MiniMap';

// Total grid size: 6400 x 4800
const maxTranslateX = 0; // left or right from origin
const maxTranslateY = 0; // up or down from origin
const minTranslateX = -6400;
const minTranslateY = -4800;

let mouseDownDocPosition = null;

const constrainX = (translateX, containerWidth) => {
  const scaledX = 1/scale * translateX;
  return (scale/1) * Math.min(maxTranslateX, Math.max(minTranslateX + containerWidth * 1/scale, scaledX));
}

const constrainY = (translateY, containerHeight) => {
  const scaledY = 1/scale * translateY;
  return (scale/1) * Math.min(maxTranslateY, Math.max(minTranslateY + containerHeight * 1/scale, scaledY));
}

let scale = 1;
let translateX = 0;
let translateY = 0;

const DocDesktopContainer = styled.div`
    display: block;
    // width: 100%;
    border: black 1px solid;
    margin-bottom: 10px;
    position: absolute;
    top: 50px;
    bottom: 115px;
    overflow: hidden;
    left: 10px;
    // right: 50px;
    right: 10px;
`;

const NoteContainer = styled.div`
  transform-origin: 0px 0px;
  height: 100%;
  width: 100%;
`;

const BoundsContainer = styled.div`
  overflow: hidden;
`;

const parentsFirstId = (element) => {
  let node = element;
  
  while (!node.id && node.tagName !== 'html') {
    node = node.parentElement;
  }

  return node.id;
}

const ancestorClassContains = (element, className) => {
  let node = element;
  while (node.parentElement) {
    if (node.classList.contains(className)) {
      return true;
    }
    node = node.parentElement;
  }
  return false;
}

const parentsUntilClass = (element, className) => {
  let node = element;
  while (!node.classList.contains(className)) {
    console.log(node)
    if (node.parentElement) {
      node = node.parentElement;
    } else {
      return null;
    }
  }
  return node;
}

const isDocWithinBounds = (doc, {top, left, width, height}, scale, translateX, translateY) => {
  const scalar = 1/scale;

  const scaledVals = {
    top: (top) * scalar - translateY * scalar,
    left: (left) * scalar - translateX * scalar,
    width: width * scalar,
    height: height * scalar
  };

  const isInBounds = doc.left >= scaledVals.left &&
    doc.top >= scaledVals.top &&
    doc.left + 30 <= scaledVals.left + scaledVals.width &&
    doc.top + 23 <= scaledVals.top + scaledVals.height;

  return isInBounds;
}

const findDocsWithinBounds = (allDocs, bounds, scale, translateX, translateY) => {
  const docsInBounds = allDocs
    .filter((doc) => isDocWithinBounds(doc, bounds, scale, translateX, translateY));

  return docsInBounds;
}

const DocDesktop = ({allDocs, selectDocs, deselectDocs, selectedDocIds, filteredInDocIds, focusedSearchResult, hasFocusedSearchResult}) => {
  const dispatch = useDispatch();
  const [stateScale, setScale] = useState(1);
  const [isPanning, setIsPanning] = useState(false);
  const [isDraggingDocuments, setIsDraggingDocuments] = useState(false);
  const [panCoords, setPanCoords] = useState(null);
  const [uiSelectedDocs, setUiSelectedDocs] = useState([]);
  const [isDragSelecting, setIsDragSelecting] = useState(false);
  const [dragSelectDimensions, setDragSelectDimensions] = useState({top: 0, height: 0, left: 0, width: 0})

  const ref = useRef();
  const boundsRef = useRef();
  const minimapRef = useRef();

  const handleClick = (e) => {

    const elementCoords = e.target.getBoundingClientRect();

    const mouseX = getXCoordinateFromEvent(e) - elementCoords.x;
    const mouseY = getYCoordinateFromEvent(e) - elementCoords.y;

    const scalar = 1 / stateScale;

    const diffX = mouseX;
    const diffY = mouseY;

    const x = (scalar * diffX) - 10;
    const y = (scalar * diffY) - 10;

    // if (!e.getModifierState('Alt') && !e.getModifierState('Meta')) {
      // console.log('here 3')
      // console.log(isPanning)
      // setUiSelectedDocs([]);
    // }

    if (e.getModifierState('Shift')) {
      e.preventDefault();
      
      const newDoc = createDocumentFromCoords(x, y);
      dispatch(docCreated(newDoc));
      window.scrollTo(0, 0);
    }

    handleMouseUp(e);
  };

  const calculateSelectionArea = (event, panCoords) => {
    const mouseX = event.clientX - boundsRef.current.getBoundingClientRect().left;
    const mouseY = event.clientY - boundsRef.current.getBoundingClientRect().top;

    const diffX = mouseX - panCoords.x;
    const diffY = mouseY - panCoords.y;

    const left = Math.min(mouseX, panCoords.x);
    const top = Math.min(mouseY, panCoords.y);

    return {
      left,
      top,
      width: Math.abs(diffX),
      height: Math.abs(diffY)
    };
  }

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = parentsUntilClass(e.target, 'doc-canvas');
    if (!target) { return; }

    const elementCoords = target.getBoundingClientRect();

    const mouseX = getXCoordinateFromEvent(e) - elementCoords.x;
    const mouseY = getYCoordinateFromEvent(e) - elementCoords.y;

    const scalar = 1 / stateScale;

    const diffX = mouseX;
    const diffY = mouseY;

    const x = (scalar * diffX) - 10;
    const y = (scalar * diffY) - 10;

    const newDoc = createDocumentFromCoords(x, y);
    dispatch(docCreated(newDoc));
    window.scrollTo(0, 0);
  }

  const handleMouseMove = (event) => {
    if (isDragSelecting) {
      const newDragSelectDimensions = calculateSelectionArea(event, panCoords);
      setDragSelectDimensions(newDragSelectDimensions);
    }

    if (isPanning) {
      const mouseX = getXCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().left;
      const mouseY = getYCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().top;

      const diffX = panCoords.x - mouseX;
      const diffY = panCoords.y - mouseY;

      const currentTranslateX = translateX - diffX;
        // constrainX(translateX - diffX, boundsRef.current.clientWidth);
      const currentTranslateY = translateY - diffY;
        // constrainY(translateY - diffY, boundsRef.current.clientHeight);

      ref.current.style.transform = `translateX(${currentTranslateX}px) translateY(${currentTranslateY}px) scale(${scale})`;

      updateZoomPaneBounds(boundsRef, minimapRef, currentTranslateX, currentTranslateY);
    }

    if (isDraggingDocuments) {
      const scalar = 1 / stateScale;

      const mouseX = getXCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().left;
      const mouseY = getYCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().top;

      const diffX = scalar * (panCoords.x - mouseX);
      const diffY = scalar * (panCoords.y - mouseY);

      const movedDocs = uiSelectedDocs.map(selectedDoc => {
        return {
          ...selectedDoc,
          top: selectedDoc.top - diffY,
          left: selectedDoc.left - diffX,
        };
      });
      
      dispatch(docsMoved(movedDocs));
    }
  };

  const handleMouseUp = (event) => {

    if (!isPanning && !isDraggingDocuments && !isDragSelecting) {
      return;
    }

    if (isDragSelecting) {
      const selectedIdsMap = uiSelectedDocs
        .map(doc => doc.id)
        .reduce((acc, docId) => {acc[docId] = true; return acc;}, {});
      
      const newDragSelectDimensions = calculateSelectionArea(event, panCoords);
      const allDocsWithoutFilteredOutDocs = allDocs.filter(doc => filteredInDocIds.includes(doc.id))
      const newlySelectedDocs = findDocsWithinBounds(allDocsWithoutFilteredOutDocs, newDragSelectDimensions, scale, translateX, translateY);

      const newUISelectedDocs = newlySelectedDocs.filter(doc => !selectedIdsMap[doc.id]);

      setUiSelectedDocs([
        ...uiSelectedDocs,
        ...newUISelectedDocs
      ])
    }

    const mouseX = getXCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().left;
    const mouseY = getYCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().top;

    const diffX = panCoords.x - mouseX;
    const diffY = panCoords.y - mouseY;

    if (isPanning) {
      translateX -= diffX;
      translateY -= diffY; 

      // translateX = constrainX(translateX, boundsRef.current.clientWidth);
      // translateY = constrainY(translateY, boundsRef.current.clientHeight);
    }
    
    if (isDraggingDocuments) {
      const scalar = 1 / stateScale;
      const movedDocs = uiSelectedDocs.map(selectedDoc => {
        return {
          ...selectedDoc,
          top: selectedDoc.top - (scalar * diffY),
          left: selectedDoc.left - (scalar * diffX),
        };
      });

      if (movedDocs.length > 1) {
        setUiSelectedDocs(movedDocs);
      } else {
        setUiSelectedDocs([]);
      }
      
      dispatch(docsMoved(movedDocs));
    }
    
    if (mouseX === panCoords.x && mouseY === panCoords.y) {
      setUiSelectedDocs([]);
    }

    setIsPanning(false);
    setIsDraggingDocuments(false);
    setIsDragSelecting(false);
    setDragSelectDimensions(null);
  };

  const isMouseTargetDocument = (target) => {
    return target.classList.contains('desktop-document')
      || target.parentElement.classList.contains('desktop-document')
      || target.tagName === 'path'
      || target.tagName === 'svg';
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragSelectDimensions(null);

    if (event.button !== 0) return;

    const target = event.target;

    const mouseX = getXCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().left;
    const mouseY = getYCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().top;

    if (isMouseTargetDocument(target)) {
      const docId = parentsFirstId(target);
      const doc = allDocs.find(doc => doc.id === docId);
      mouseDownDocPosition = {top: doc.top, left: doc.left};
    }

    if (event.getModifierState('Alt') || event.getModifierState('Meta')) {
      if (isMouseTargetDocument(target)) {
        const docId = parentsFirstId(target);
        const doc = allDocs.find(doc => doc.id === docId);
        
        const selectedIdsMap = uiSelectedDocs
          .map(doc => doc.id)
          .reduce((acc, docId) => {acc[docId] = true; return acc;}, {});

        if (selectedIdsMap[docId]) {
          setUiSelectedDocs(uiSelectedDocs.filter(doc => doc.id !== docId));
        } else {
          setUiSelectedDocs([
            ...uiSelectedDocs,
            doc
          ]);
        }
      } else {
        setPanCoords({
          x: mouseX,
          y: mouseY
        });
        setIsDragSelecting(true);
      }
      
      return;
    }

    if (isMouseTargetDocument(target)) {
      if (!ancestorClassContains(target, 'desktop-document__visible')) {
        // don't drag filtered-out items
        setIsPanning(true);
        setPanCoords({
          x: mouseX,
          y: mouseY
        });
        return;
      }
      setIsDraggingDocuments(true);
      const docId = parentsFirstId(target);
      if (!uiSelectedDocs.find(doc => doc.id === docId)) {
        setUiSelectedDocs([allDocs.find(doc => doc.id === docId)]);
      }

    } else {
      setIsPanning(true);
    }

    setPanCoords({
      x: mouseX,
      y: mouseY
    });
  };

  // const curryDragStop = doc => {
  //   return (e, ui) => {
  //     const newDoc = {
  //       ...doc,
  //       top: ui.y,
  //       left: ui.x,
  //     };
  //     if (doc.top !== newDoc.top || doc.left !== newDoc.left) {
  //       dispatch(docMoved(newDoc));
  //     }
  //   };
  // };

  const curryOnClick = doc => {
    return (e, isDragging) => {
      // if (e.getModifierState('Meta')) {
      if (!isDragging) {
        if (selectedDocIds.includes(doc.id)) {
          return deselectDocs([doc]);
        }

        return selectDocs([doc])
      // }
      }
    };
  };

  const onDocClick = doc => {
    if (!filteredInDocIds.includes(doc.id)) {
      return;
    }
    if (doc.top !== mouseDownDocPosition.top || doc.left !== mouseDownDocPosition.left) { return; }
    if (selectedDocIds.includes(doc.id)) {
      return deselectDocs([doc]);
    }

    return selectDocs([doc])
  };

  // const curryOnDoubleClick = doc => {
  //   return (e) => {
  //     if (selectedDocIds.includes(doc.id)) {
  //       return deselectDocs([doc]);
  //     }

  //     return selectDocs([doc])
  //   };
  // };

  const debounceSetScale = debounce(setScale, 500);
  
  const zoom = (event) => {
    event.preventDefault();
    event.stopPropagation();

//18
// -18
    let newScale = scale + (event.deltaY * -0.01);
  
    newScale = Math.min(Math.max(.19, newScale), 8);

    const mouseX = getXCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().left;
    const mouseY = getYCoordinateFromEvent(event) - boundsRef.current.getBoundingClientRect().top;

    
    const debugTranslateX = translateX;
    const debugTranslateY = translateY;

    const newX = (translateX - mouseX) * newScale/scale + mouseX;
    const newY = (translateY - mouseY) * newScale/scale + mouseY;

    translateX = constrainX(newX, boundsRef.current.clientWidth);
    translateY = constrainY(newY, boundsRef.current.clientHeight);

    translateX = newX;
    translateY = newY;

    ref.current.style.transform = `translateX(${translateX}px) translateY(${translateY}px) scale(${newScale})`;
    
    scale = newScale;

    const zoomPaneBounds = updateZoomPaneBounds(boundsRef, minimapRef, translateX, translateY);

    // this makes the scale available to the render function, specifically the react draggables
    debounceSetScale(newScale);
  }  

  const calculateZoomPaneBounds = (boundsRef, minimapRef, transX, transY) => {
    if (boundsRef.current && minimapRef.current) {
      const scalar = 1 / scale;

      return {
        left: transX * scalar / (minTranslateX) * 100,
        top: transY * scalar / (minTranslateY) * 100,
        width: boundsRef.current.clientWidth / (-minTranslateX) * 100 * 1/scale,
        height: boundsRef.current.clientHeight / (-minTranslateY) * 100 * 1/scale,
      };
    }
  };

  const updateZoomPaneBounds = (boundsRef, minimapRef, transX, transY) => {
    if (boundsRef.current && minimapRef.current) {
      const zoomPaneBounds = calculateZoomPaneBounds(boundsRef, minimapRef, transX, transY);

      minimapRef.current.style.width = zoomPaneBounds.width + '%';
      minimapRef.current.style.height = zoomPaneBounds.height + '%';
      minimapRef.current.style.left = zoomPaneBounds.left + '%';
      minimapRef.current.style.top = zoomPaneBounds.top + '%';
    }
  }

  const constrainBy = (value, min, max) => {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  }

  const handleMiniMapPanned = ({x, y}) => {
    const minimapWidth = 240;
    const minimapHeight = 180;
    const minimapZoomPaneCenterX = minimapRef.current.clientWidth / 2;
    const minimapZoomPaneCenterY = minimapRef.current.clientHeight / 2;

    // const newX = constrainBy(x - minimapZoomPaneCenterX, 0, minimapWidth);
    // const newY = constrainBy(y - minimapZoomPaneCenterY, 0, minimapHeight);
    const newX = x - minimapZoomPaneCenterX;
    const newY = y - minimapZoomPaneCenterY;


    const pointX = newX / minimapWidth * minTranslateX * scale;
    const pointY = newY / minimapHeight * minTranslateY * scale;

    
    // translateX = constrainX(pointX, boundsRef.current.clientWidth);
    // translateY = constrainY(pointY, boundsRef.current.clientHeight);
    translateX = pointX;
    translateY = pointY;

    ref.current.style.transform = `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`;
    updateZoomPaneBounds(boundsRef, minimapRef, translateX, translateY);
  };

  useEffect(() => {
    updateZoomPaneBounds(boundsRef, minimapRef, translateX, translateY);
  });

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp, {passive: false});
    window.addEventListener('touchend', handleMouseUp, {passive: false});
    window.addEventListener('mousemove', handleMouseMove, {passive: false});
    window.addEventListener('touchmove', handleMouseMove, {passive: false});
    boundsRef.current.addEventListener('wheel', zoom, {passive: false});

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
      if (boundsRef.current) { boundsRef.current.removeEventListener('wheel', zoom); }
    };
  }, [handleMouseUp, handleMouseMove]);

  useEffect(() => {
    return () => {
      scale = 1;
      translateX = 0;
      translateY = 0;
    };
  }, []);

  const uiSelectedDocIds = uiSelectedDocs.map(doc => doc.id);

  return (
    <BoundsContainer>
      <DocDesktopContainer
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        ref={boundsRef}
        // onWheel={zoom}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        style={{background: 'rgb(40, 40, 60)'}}
      >
        <NoteContainer id='container' ref={ref} className='doc-canvas'>
          <div
            id="boundsthing"
            style={{
              position: 'absolute',
              left: '0px',
              width: '6400px',
              top: '0px',
              height: '4800px',
              background: 'rgb(40, 40, 60)'
            }}
          />
          {allDocs.map((doc, index) => {
              const isVisible = filteredInDocIds.includes(doc.id);
              const isSelected = selectedDocIds.includes(doc.id);
              const isUiSelected = uiSelectedDocIds.includes(doc.id)

              return (
                // <Draggable
                //   key={index}
                //   onStop={curryDragStop(doc)}
                //   position={{x: doc.left, y: doc.top}}
                //   id={doc.id}
                //   scale={stateScale}
                //   className={'test'}
                // >
                <div
                  key={doc.id}
                  id={doc.id}
                  onClick={(e) => onDocClick(doc)}
                  className={`desktop-document ${isVisible ? 'desktop-document__visible' : ''}`}
                  style={{
                    position: "absolute",
                    display: 'block',
                    left: doc.left,
                    top: doc.top,
                    border: hasFocusedSearchResult && focusedSearchResult.docId === doc.id ? 'white 1px solid' : 'none',
                    background: isUiSelected ? 'gray' : 'none',
                    whiteSpace: "nowrap",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    cursor: isVisible ? 'pointer' : 'default',
                  }}
                >
                  {/* <div
                    onClick={curryOnClick(doc)}
                    onDoubleClick={curryOnDoubleClick(doc)}
                    style={{display: 'block', position: 'absolute'}}
                  > */}
                    {isSelected && <FileFilled
                      className='desktop-document'
                      style={{
                        opacity: isVisible ? 1 : 0.2,
                        color: 'orange',
                        cursor: isVisible ? 'pointer': ''
                      }}
                    />}
                    {!isSelected && <FileFilled
                      className='desktop-document'
                      style={{
                        opacity: isVisible ? 1 : 0.2,
                        color: 'red',
                        cursor: isVisible ? 'pointer': ''
                      }}
                    />}
                    <DocumentLabel style={{
                        opacity: isVisible ? 1 : 0.2,
                        color: isSelected ? 'orange' : '#f9f9f9',
                        cursor: isVisible ? 'pointer': '',
                    }}>{doc.title}</DocumentLabel>
                  </div>
                // </div>
              );
          })}
        </NoteContainer>
        
        
          <DesktopMiniMap
            allDocs={allDocs}
            selectedDocIds={selectedDocIds}
            filteredInDocIds={filteredInDocIds}
            ref={minimapRef}
            handlePan={handleMiniMapPanned}
            onContextMenu={(e) => {e.stopPropagation(); e.preventDefault();}}
            focusedSearchResult={focusedSearchResult}
            hasFocusedSearchResult={hasFocusedSearchResult}
          />
        

        {isDragSelecting && dragSelectDimensions && (
          <div id='drag-selector' style={{
            // width: '200px',
            // height: '200px',
            width: `${dragSelectDimensions.width}px`,
            height: `${dragSelectDimensions.height}px`,
            top: `${dragSelectDimensions.top}px`,
            left: `${dragSelectDimensions.left}px`,
            zIndex: 10,
            background: '#a8e4a0',
            position: 'absolute',
            opacity: 0.3,
            border: '#a8e4a0 1px solid'
          }}/>
        )}
      </DocDesktopContainer>
    </BoundsContainer>
  );
}

export default DocDesktop;
