import uuidv4 from 'uuid/v4';

import { API_URL } from './constants';

import { notification } from 'antd';
import { decryptString, encryptString } from './services/crypto';

const Document = (id, title, content, left = 0, top = 0) => {
  return {
    id,
    title,
    content,
    left,
    top,
  };
}

const showErrorSavingNotification = () => {
  notification['error']({
    message: 'Error Saving',
    description:
      'There was an error when saving. Check your internet connection and try again.',
  });
}

const handleSaveErrors = (res) => {
  if (!res.ok) {
    showErrorSavingNotification();
  }
  return res;
};

const documentTitleFromDate = () => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' });
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(Date.now());

  return `${month} ${day}, ${year}`;
};

export const createDocumentFromCoords = (left, top) => {
  return createDocument(documentTitleFromDate(), '', left, top);
}

export const createDocument = (title = documentTitleFromDate(), content = '', left = 0, top = 0) => {
  const id = uuidv4();
  const newDocument = Document(id, title, content, left, top);

  return newDocument;
}

let lastRequest;

const prepareDocToSave = doc => {
  const docToSave = {
    ...doc,
    title: encryptString(doc.title),
    content: encryptString(doc.content),
    blocks: encryptString(doc.blocks),
    enc: true
  };
  
  return JSON.stringify(docToSave);
}

export const saveDoc = (doc) => {
  clearTimeout(lastRequest);
  lastRequest = setTimeout(() => {
    fetch(`${API_URL()}/doc/${doc.id}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: prepareDocToSave(doc),
      credentials: 'include'
    })
    .then(handleSaveErrors)
    .catch(showErrorSavingNotification);
  }, 1000);
}

export const createDoc = (doc) => {
  fetch(`${API_URL()}/doc/${doc.id}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: prepareDocToSave(doc),
    credentials: 'include'
  })
  .then(handleSaveErrors)
  .catch(showErrorSavingNotification);
}

export const deleteDoc = (docId) => {
  fetch(`${API_URL()}/doc/${docId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include'
  })
  .then(handleSaveErrors)
  .catch(showErrorSavingNotification);
}

export const saveDocPosition = (doc) => {
  clearTimeout(lastRequest);
  lastRequest = setTimeout(() => {
    fetch(`${API_URL()}/docposition/${doc.id}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({id: doc.id, top: doc.top, left: doc.left}),
      credentials: 'include'
    })
    .then(handleSaveErrors)
    .catch(showErrorSavingNotification);
  }, 500);
}

export const saveDocsPositions = (docs) => {
  const justTheCoords = docs.map(doc => {
    return {id: doc.id, top: doc.top, left: doc.left}
  });
  clearTimeout(lastRequest);
  lastRequest = setTimeout(() => {
    fetch(`${API_URL()}/docpositions`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(justTheCoords),
      credentials: 'include'
    })
    .then(handleSaveErrors)
    .catch(showErrorSavingNotification);
  }, 500);
}
// export const loadDocumentsAndChanges = async () => {
//   const repo = await fetch(`${API_URL()}/docs`, {
//       credentials: 'include',
//       method: 'GET'
//     })
//     .then(response => response.json())
//     .then(rawrepo => {
//       return {
//         changes: rawrepo.changes,
//         docs: rawrepo.docs.map(doc => {
//           return {
//             ...doc.content
//           };
//         })
//       }
//     });
// console.log(repo)
//   return repo;
// }

const decryptContent = doc => {
  if (!doc.enc) { return doc; }
  
  return {
    ...doc,
    content: decryptString(doc.content),
    title: decryptString(doc.title),
    blocks: doc.blocks ? decryptString(doc.blocks) : null,
  };
};

export const loadDocumentsAndChanges = async () => {
  const repo = await fetch(`${API_URL()}/docs`, {
    method: 'GET',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'}
  })
    .then(response => response.json())
    .then(rep => {
      console.log(rep)
      console.log(rep.docs.map(doc => doc.content).map(decryptContent))
      return {
        changes: rep.changes,
        docs: rep.docs.map(doc => doc.content).map(decryptContent).reduce((acc, doc) => {acc[doc.id]=doc; return acc}, {})
      }
    });

  return repo;
}

// export const getEditHistoryBetween = (begin, end, editHistory) => {
//     let startIndex = 0,
//         endIndex = Math.max(editHistory.length - 1, 0);

//     for (let index = 0; index < editHistory.length; index++) {
//         const { timestamp } = editHistory[index];
//         if (timestamp >= begin) {
//             startIndex = index;
//             break;
//         }
//     }

//     for (let index = editHistory.length - 1; index >= 0; index--) {
//         const { timestamp } = editHistory[index];
//         if (timestamp <= end) {
//             endIndex = index;
//             break;
//         }
//     }

//     return editHistory.slice(startIndex, endIndex + 1);
// }
