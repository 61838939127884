import { API_URL } from './constants';

import { clearLocalKey, reEncryptKeyWithNewPassword } from './services/crypto';

export const signIn = async (username, password) => {
  const user = await fetch(`${API_URL()}/auth/signin`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password}),
      credentials: 'include'
    })
    .then(response => {
      return response.json();
    });

  return user;
}

export const signOut = async () => {
  const response = await fetch(`${API_URL()}/auth/logout`, {
      method: 'POST',
      credentials: 'include'
    })
    .then(response => {
      clearLocalKey();
      return response.json();
    });

  return response;
}

export const changePassword = async (oldPassword, newPassword) => {
  const encryptedKey = reEncryptKeyWithNewPassword(newPassword);

  const response = await fetch(`${API_URL()}/auth/changepassword`, {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({oldPassword, newPassword, encryptedKey}),
    })
    .then(response => {
      return response.json();
    });

  return response;
}

export const signUp = async (username, password, encryptedKey) => {
  const user = await fetch(`${API_URL()}/auth/signup`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password, encryptedKey}),
      credentials: 'include'
    })
    .then(response => response.json());

  return user;
}

export const checkSignInStatus = async () => {
  const user = await fetch(`${API_URL()}/me`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include'
  })
  .then(response => response.json());

  return user;
};

export const verifyEmail = async (email, token) => {
  const result = await fetch(`${API_URL()}/verify-email`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email, token}),
    credentials: 'include'
  })
  .then(response => response.json());

  return result;
};
