import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Editor from './Editor';
import DraftInput from './DraftEditor';
import MiniMap from './MiniMap';
import styled from 'styled-components';
import { Card } from 'antd';


const Wrapper = styled.div`
  // padding-right: 100px;
`;

const CommandHelper = styled.span`
  font-weight: bold;
`;

const CommandSection = styled.h3`
  margin-top: 20px;
  color: orange;
`;

const ShortcutHelper = () => {
  return (
    <Card style={{fontSize: '10px', background: 'white', margin: '40px'}}>
      <h2>Commands and shortcuts</h2>
      <ul style={{listStyleType: 'none', padding: '0px'}}>
        <CommandSection>Creating notes</CommandSection>
        <li><CommandHelper>CMD + K</CommandHelper>: create a new note and open it (adds the note to the top right corner of the desktop/minimap)</li>
        
        <CommandSection>Toggling the search panel</CommandSection>
        <li><CommandHelper>CMD + P</CommandHelper>: open/close the search panel</li>
        <li><CommandHelper>SHIFT twice quickly</CommandHelper>: open/close the search panel</li>
        <li><CommandHelper>ESC</CommandHelper>: close the search panel</li>
        
        <CommandSection>Interacting with notes on the search panel</CommandSection>
        <li><CommandHelper>Click a note</CommandHelper>: open/close that note</li>
        <li><CommandHelper>CMD + left click and drag</CommandHelper>: select multiple notes to move them collectively</li>
        <li><CommandHelper>Right click somewhere on the desktop</CommandHelper>: create a note in that spot</li>
      </ul> 
    </Card>
  );
}



const EditorPanel = ({docId, isSearchVisible}) => {
  // const docs = useSelector(state => {
  //   return Object.values(state.documents.selectedDocs);
  // });
  const docs = useSelector(state => {
    return state.documents.selectedDocs;
  });

  const hasDocs = docs && docs.length > 0;

  

  return (
    <Wrapper
      className="editor-panel"
      style={{
        paddingRight: '100px'
      }}
    >
      <div id="editor-panel__content">
        
      {!hasDocs && <ShortcutHelper />}
      {/* {
        docs.map((doc) => {
          return (
            <Editor
              docId={doc.id}
              key={doc.id}
            />
          );
        })
      } */}
      {hasDocs && <DraftInput docs={docs} isSearchVisible={isSearchVisible}/>}
      </div>
      {<MiniMap docs={docs} isSearchVisible={isSearchVisible} />}
    </Wrapper>
  );
  
};



































































export default EditorPanel;
