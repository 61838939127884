import React from 'react';
import styled from 'styled-components';

export const DocumentIcon = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  background: red;
`;

export const DocumentLabel = styled.label`
  color: #f9f9f9;
  display: inline-block;
  font-size: 10px;
  max-height: 30px;
  max-width: 120px;
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 2px;
`;
