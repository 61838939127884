import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { notification } from 'antd';

import Logo from '../Logo.js';
import { Button } from 'antd';
import { changePassword } from '../UserRepository';


const Section = styled.div`
    border: #ddd 1px solid;
    margin-bottom: 40px;
    padding: 30px;
`;

const FormRow = styled.div`
    margin-bottom: 10px;
`;

const InputField = styled.input`
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    padding: 12px 16px;
    max-width: 100%;
`;

const TopBar = styled.div`
    position: fixed;
    background-color: #BF7E02;
    height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    margin-bottom: 10px;
`;

const HeaderLogo = styled(Logo)`
    position: absolute;
    top: 9px;
    left: 10px;
    color: #fbf0e5;
`;

const loadingStates = {
    LOADING: 'loading',
    IDLE: 'idle'

}

const Settings = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.IDLE);
    const [validationErrorMessages, setValidationErrorMessages] = useState([]);

    const [user, docs, editHistory] = useSelector(state => {
        return [state.user, state.documents, state.editHistory];
    });

    if (!user) {
        return (<Redirect to='/' />)
    }

    const whiteSpaceTest = string => {
        return /^\s*$/.test(string);
    }

    const validateChangePasswordInputs = () => {
        let messages = [];
        
        const missingNewPassword1 = whiteSpaceTest(newPassword1);
        const missingNewPassword2 = whiteSpaceTest(newPassword2);

        if (whiteSpaceTest(oldPassword)) {
            messages.push('Old password field is empty');
        }
        if (missingNewPassword1) {
            messages.push('First new password field is empty');
        }
        if (missingNewPassword2) {
            messages.push('Second new password field is empty');
        } 
        if (!missingNewPassword1 && !missingNewPassword2 && newPassword1 !== newPassword2) {
            messages.push('New password fields do not match');
        }

        setValidationErrorMessages(messages);

        return messages.length === 0;
    }

    const showErrorUpdatingPasswordNotification = (message) => {
        notification['error']({
          message: 'Error when updating',
          description:
            message ? message : 'There was an error updating your password. Please try again.',
        });
    }

    const showPasswordUpdatedNotification = () => {
        notification['success']({
          message: 'Password updated',
          description:
            'Your password has been udpated.',
        });
    }

    const sendChangePassword = () => {
        if (validateChangePasswordInputs()) {
            setLoadingState(loadingStates.LOADING);

            changePassword(oldPassword, newPassword1)
                .then((response) => {
                    if (response.code) {
                        showErrorUpdatingPasswordNotification(response.message)
                    } else {
                        showPasswordUpdatedNotification();
                    }
                })
                .catch(() => {
                    showErrorUpdatingPasswordNotification();
                })
                .finally(() => {
                    setOldPassword('');
                    setNewPassword1('');
                    setNewPassword2('');
                    setLoadingState(loadingStates.IDLE);
                });
        }
    }

    const isLoading = loadingState === loadingStates.LOADING;
    // const isLoading = true;
    const repoToString = JSON.stringify({docs, editHistory}, null, 2)
    return (
        <div>
            <TopBar><Link to='/'><HeaderLogo></HeaderLogo></Link></TopBar>
            <div style={{padding: '80px 100px 20px 100px'}}>

                <Link to='/app' style={{color: '#BF7E02', display: 'block', marginBottom: '20px'}}><ArrowLeftOutlined style={{color: '#BF7E02'}}/> Back to app</Link>
                <Section>
                    <h3>Account</h3>
                    <h4>Change password</h4>
                    
                    <FormRow><InputField type='password' placeholder='Old password' value={oldPassword} onChange={event => setOldPassword(event.target.value)} /></FormRow>
                    <FormRow><InputField type='password' placeholder='New password' value={newPassword1} onChange={event => setNewPassword1(event.target.value)} /></FormRow>
                    <FormRow><InputField type='password' placeholder='Repeat new password' value={newPassword2} onChange={event => setNewPassword2(event.target.value)} /></FormRow>

                    {validationErrorMessages.length > 0 && validationErrorMessages.map((message, i) => {
                        return (
                            <p key={i} style={{color: 'red', marginBottom: '5px', paddingLeft: '10px'}}>{message}</p>
                        );
                    })}

                    <Button style={{textAlign: 'center', width: '170px'}} disabled={isLoading} onClick={sendChangePassword}>
                        {isLoading && <LoadingOutlined />}
                        {!isLoading && 'Change password'}
                    </Button>
                </Section>

                <Section>
                    <h3>Export data</h3>
                    <p>All notes and edit sessions are here as JSON. Copy/paste into a text file to preserve or use in other applications.</p>
                    <textarea style={{width: '100%', border: '1px solid #e6e6e6', fontSize: '8px', height: '100px'}} value={repoToString}/>
                        
                </Section>

                {/* <Section>
                    <h3>Delete Account</h3>
                </Section> */}
            </div>
        </div>
    );
}

export default Settings;
