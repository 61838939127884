import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import EventRecord from './EventRecord';
import { timeFilterApplied } from '../reducers/documents';
import { defaultEndTime, beginTimeFromHistory, days } from '../services/time';
import { getXCoordinateFromEvent, getYCoordinateFromEvent } from '../services/eventHandlers';
import { weeks } from '../services/time';


const AllHistoryContainer = styled.div`
    display: block;
    height: 100%;
    width: 100%;
    border: black 1px solid;
    position: relative;
    // margin-top: 10px;
    // background: #444;
    background: rgb(40, 40, 60);
`;

const FocusedSlice = styled.div`
    display: block;
    height: 100%;
    border: #f1f1f1 1px solid;
    position: absolute;
    background-color: white;
    opacity: 0.1;
    box-sizing: border-box;
`;

const timeFromSliceCoords = (startTime, endTime, sliceCoords) => {
  // const startTime = beginTimeFromHistory(allEditHistory);
  // const endTime = defaultEndTime();
  const durationTime = endTime - startTime;

  const startTimeSlice = (sliceCoords.startPercent / 100 * durationTime) + startTime;
  const endTimeSlice = (sliceCoords.durationPercent / 100 * durationTime) + startTimeSlice;

  return {startTime: startTimeSlice, endTime: endTimeSlice};
};

const createMonthModels = (startTime, endTime) => {
    const numberOfMonths = monthsBetween(new Date(startTime), new Date(endTime));
    let lastMonth = new Date(startTime).getMonth();
    let lastMonthTime = startTime;
    const monthModels = [];

    for (let i = 0; i < numberOfMonths; i++) {

      let nextDate = new Date(lastMonthTime);
      while (nextDate.getMonth() === lastMonth) {
        lastMonthTime += days(1);
        nextDate = new Date(lastMonthTime);
        nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 1, 0, 0, 0, 0); // midnight of first day
      }

      monthModels.push({
        month: nextDate.getMonth(),
        date: nextDate.toLocaleString('en-US', { month: 'short' }),
        percentFromStart: (nextDate.getTime() - startTime) / (endTime - startTime) * 100
      });

      lastMonth = nextDate.getMonth();
    }

    return monthModels;
};

const monthsBetween = (start, end) => {
  let months;
  months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();
  return months <= 0 ? 0 : months;
}


const AllHistoryTimeline = ({ allEditHistory }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState(0);
    const [elementCoords, setElementCoords] = useState({x: 0, y: 0, width: 0});
    const [sliceCoords, setSliceCoords] = useState({startPercent: 0, durationPercent: 100});
    const [isDateDisplayed, setIsDateDisplayed] = useState(false);
    const [focusedDate, setFocusedDate] = useState(null);
    // const [focusedDatePosition, setFocusedDatePosition] = useState(null);

    const startTime = beginTimeFromHistory(allEditHistory) - weeks(4);
    const endTime = defaultEndTime();
    const durationTime = endTime - startTime;

    const monthModels = createMonthModels(startTime, endTime);

    const dispatch = useDispatch();

    const ref = useRef();
    
    const focusedSliceStyle = {
      left: `${sliceCoords.startPercent}%`,
      width: `${sliceCoords.durationPercent}%`,
    };

    const handleMouseUp = e => {
      setIsDragging(false);
      dispatch(timeFilterApplied(
        timeFromSliceCoords(startTime, endTime, sliceCoords)
      ));
    };

    const handleMouseDown = e => {
      e.preventDefault(); // don't select text
      const rect = ref.current.getBoundingClientRect();

      setElementCoords({
        x: rect.x,
        y: rect.y,
        width: ref.current.clientWidth
      });
      
      setDragStart(getXCoordinateFromEvent(e));
      setIsDragging(true);
    };

    const getDateFromContainerPercent = (percent) => {
        const timeFromPercent = (percent * durationTime) + startTime;
        return new Date(timeFromPercent);
    };

    const printDateFromMouseEvent = e => {
        const x = getXCoordinateFromEvent(e);
        // const y = getYCoordinateFromEvent(e);
        
        const rect = ref.current.getBoundingClientRect();
        const containerElement = {
          x: rect.x,
          y: rect.y,
          width: ref.current.clientWidth
        };

        const percent = (x - containerElement.x) / (containerElement.width);
        const focusedDate = getDateFromContainerPercent(percent);
        setFocusedDate(focusedDate);

        // setFocusedDatePosition({ x: x - rect.x, y: y - rect.y });
    };

    const handleMouseEnter = e => {
        setIsDateDisplayed(true);
    };

    const handleMouseLeave = e => {
      setIsDateDisplayed(false);
    };

    const handleMouseMove = e => {
      if (isDragging) {
        const rightMax = elementCoords.x + elementCoords.width;
        const left = Math.min(getXCoordinateFromEvent(e), dragStart);
        const length = getXCoordinateFromEvent(e) > rightMax ?
          rightMax - dragStart :
          Math.abs(Math.max(getXCoordinateFromEvent(e), elementCoords.x) - dragStart);

        const startPercent = Math.max(
          (left - elementCoords.x) / elementCoords.width * 100,
          0
        );
        const durationPercent = length / elementCoords.width * 100;

        setSliceCoords({
          startPercent,
          durationPercent
        });
      }
    };

    useEffect(() => {
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, [handleMouseMove]);

    useEffect(() => {
      window.addEventListener('mouseup', handleMouseUp);
  
      return () => {
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }, [handleMouseUp]);

    return (
        <AllHistoryContainer onMouseDown={handleMouseDown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={printDateFromMouseEvent} ref={ref}>
            {monthModels.map((monthModel, index) => {
                const style = {
                    position: 'absolute',
                    height: '100%',
                    width: '1px',
                    top: '0px',
                    border: '#666 1px dashed',
                    left: `calc(${monthModel.percentFromStart}% - 1px)`,
                    // opacity: '0.8',
                    zIndex: 0,
                    cursor: 'normal'
                };
                return (
                  <div style={style} key={index}>
                      <span style={{
                          position: 'relative',
                          bottom: '-58px',
                          fontSize: '10px',
                          color: 'black',
                          width: '100px',
                          display: 'block',
                          paddingLeft: '3px'
                      }}>{monthModel.date}</span>
                  </div>
                );
            })}
            {allEditHistory.map((edit, index) => {
                const color = edit.isFocused ? 'red' : edit.isInSearchResults ? '#BF7E02' : '#eee';
                const style={
                    left: `calc(${edit.startPercent}% - ${edit.isFocused || edit.isInSearchResults ? '4px' : '2px'})`,
                    top: `calc(${edit.heightPercent}% - ${edit.isFocused || edit.isInSearchResults ? '4px' : '2px'})`,
                    width: edit.isFocused || edit.isInSearchResults ? '8px' : '4px',
                    height: edit.isFocused || edit.isInSearchResults ? '8px' : '4px',
                    // backgroundColor: `${edit.color}`
                    // backgroundColor: '#ccc'
                    // backgroundImage: `linear-gradient(to bottom right, ${edit.color}, #eee)`,
                    // backgroundImage: `linear-gradient(to bottom right, ${color}, white)`,
                    opacity: 0.8,
                    backgroundColor: color,
                    zIndex: edit.isFocused ? 8 : edit.isInSearchResults ? 7 : 6
                };

                return <EventRecord key={index} style={style} />
            })}
            {isDateDisplayed && focusedDate &&
              <div style={{
                  fontSize: '11px',
                  zIndex: 9999,
                  position: 'absolute',
                  // top: focusedDatePosition.y - 8,
                  // left: focusedDatePosition.x + 7,
                  top: '-17px',
                  textAlign: 'left',
                  width: '50%',
                  left: '0px',
                  height: '16px',
                  color: 'black'}}>{focusedDate.toLocaleDateString("en-US", {month: 'short', day: 'numeric', hour: 'numeric', year: 'numeric'})}</div>}

              {sliceCoords && 
                <div style={{
                  fontSize: '11px',
                  zIndex: 9999,
                  position: 'absolute',
                  // top: focusedDatePosition.y - 8,
                  // left: focusedDatePosition.x + 7,
                  top: '-17px',
                  textAlign: 'left',
                  width: '50%',
                  right: '0px',
                  height: '16px',
                  color: 'black'}}>   
                    <span style={{display: 'inline-block'}}>{getDateFromContainerPercent(sliceCoords.startPercent / 100).toLocaleDateString("en-US", {month: 'short', year:'numeric', day: 'numeric', hour: 'numeric'})}<span style={{display: 'inline-block', width: '5px'}}></span>-</span>
                    <span style={{display: 'inline-block'}}><span style={{display: 'inline-block', width: '5px'}}></span>{getDateFromContainerPercent((sliceCoords.startPercent + sliceCoords.durationPercent) / 100).toLocaleDateString("en-US", {month: 'short', day: 'numeric', hour: 'numeric',  year:'numeric'})}</span>
                </div>
              }
            <FocusedSlice style={focusedSliceStyle} />
        </AllHistoryContainer>
    );
}

export default AllHistoryTimeline;
