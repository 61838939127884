import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { statement_timeout } from 'pg/lib/defaults';

import { signIn as signInAction, signUp as signUpAction, checkSignInStatus as signInStatus} from '../UserRepository';

export const signIn = createAsyncThunk('user/signin', async ({email, password}) => {
  const user = await signInAction(email, password);

  if (!user) {
    return null;
  }

  return {
    user: {
      email: user.username,
      username: user.username
    }
  };
});

export const checkSignInStatus = createAsyncThunk('user/signinstatus', async () => {
  const user = await signInStatus();

  return {
    user: {
      email: user.username,
      username: user.username
    }
  };
});

// export const signUp = createAsyncThunk('user/signup', async ({email, password}) => {
//   const user = await signUpAction(email, password);

//   return {
//     user: {
//       email: user.username,
//       username: user.username
//     }
//   };
// });

export const slice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, {payload}) => {
      return {
        username: payload.username
      }
    }
  },
  // extraReducers: {
  //   [signIn.pending]: (state, action) => {
  //     return {
  //       ...state,
  //       status: 'signingin',
  //     };
  //   },
  //   [signIn.fulfilled]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload,
  //       status: 'signedin'
  //     };
  //   },
  //   [signUp.pending]: (state, action) => {
  //     return {
  //       ...state,
  //       status: 'signingup',
  //     };
  //   },
  //   [signUp.fulfilled]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload,
  //       status: 'signedin'
  //     };
  //   },
  //   [checkSignInStatus.pending]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload,
  //       status: 'checkingactive'
  //     };
  //   },
  //   [checkSignInStatus.fulfilled]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload,
  //       status: 'activeuser'
  //     };
  //   },
  //   [checkSignInStatus.rejected]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload,
  //       status: 'noactiveuser'
  //     };
  //   }
  // }
});

export const { setUser } = slice.actions;

export default slice.reducer;
