// from https://gist.github.com/enjalot/5470760
export const uuidToColor = uuid => {
  if (!uuid) return null;
  var parts = uuid.split('-')
  var ints = parts.map(function(d) { return parseInt(d,16) })
  var code = ints[0]

  var blue = (code >> 16) & 31;
  var green = (code >> 21) & 31;
  var red = (code >> 27) & 31;

  return "rgb(" + (red << 3) + "," + (green << 3) + "," + (blue << 3) + ")";
}
