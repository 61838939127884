import React from 'react';
import styled from 'styled-components';

const SessionItem = styled.div`
  font-size: 12px;
  color: #ddd;
  cursor: pointer;
`;

const Wrapper = styled.div`
  height: 150px;
  overflow: scroll;
`;

const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });

const formatDate = (timestamp) => {
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(timestamp);

  return `${day}-${month}-${year }`;
};

const SessionHistory = ({allEditHistory, allDocs}) => {
  const reverse = [...allEditHistory].reverse();
  
  return (
    <Wrapper>
      {reverse.map(edit => {
        return (
          <SessionItem>{formatDate(edit.timestamp)} {allDocs.find(d => d.id===edit.docId).title}</SessionItem>
        );
      })}
    </Wrapper>
  );
}

export default SessionHistory;
