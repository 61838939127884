import React, { Component } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import AllHistoryTimeline from './AllHistoryTimeline';
import FocusedHistoryTimeline from './FocusedHistoryTimeline';

import { beginTimeFromHistory, defaultEndTime, weeks } from '../services/time';
// const isActivationKey = ({key}) => key === 'Control' || key === 'Meta';
import { uuidToColor } from '../services/colors';

const editHistoryViewModel = (editHistory, begin, end, focusedSearchResult, hasFocusedSearchResult, searchResultIds) => {
    return editHistory.map(edit => {
        const unixTime = new Date(edit.starttime).getTime();
        if (!edit.docid) {console.log(edit)}
        let color = '#333';
        let isFocused = false;
        let isInSearchResults = false;
        if (hasFocusedSearchResult) {
          if (edit.docid === focusedSearchResult.docId) {
            isFocused = true;
          } else if (searchResultIds.includes(edit.docid)) {
            isInSearchResults = true;
          }
        }

        return {
            ...edit,
            startPercent: (unixTime - begin) / (end - begin) * 100,
            heightPercent: new Date(unixTime).getHours() / 24 * 100,
            color: uuidToColor(edit.docid),
            // color: color
            isFocused,
            isInSearchResults
        };
    });
}

const HistoricalEventSelector = ({allEditHistory, focusedSearchResult, hasFocusedSearchResult, searchResultIds}) => {
  const allHistoryBegin = beginTimeFromHistory(allEditHistory) - weeks(4);
  
  const allHistoryEnd = defaultEndTime();

  return (
      <div style={{height: '100%', width: '100%'}}>
          {/* <FocusedHistoryTimeline
              editHistory={editHistoryViewModel(visibleEditHistory, focusedBegin, focusedEnd)}
          /> */}

          <AllHistoryTimeline
              allEditHistory={editHistoryViewModel(allEditHistory, allHistoryBegin, allHistoryEnd, focusedSearchResult, hasFocusedSearchResult, searchResultIds)}
              // focusedSliceStartPercent={focusedSliceStartPercent}
              // focusedSliceDurationPercent={focusedSliceDurationPercent}
          />
          {/* <div>
              {new Date(focusedBegin).toString()} - {new Date(focusedEnd).toString()}
          </div> */}
      </div>
  );
};

// class HistoricalEventSelector extends Component {
//     state = {
//         beginAtScrollStart: null,
//         endAtScrollStart: null,
//         isMoving: false,
//         startMoveX: null,
//         startMoveY: null,
//         currentMoveX: null,
//         currentMoveY: null,
//         stopTime: Date.now(),
//     }

//     constructor() {
//         super();
//         this.handleWindowMouseMove = this.handleWindowMouseMove.bind(this);
//         this.handleWindowKeyUp = this.handleWindowKeyUp.bind(this);
//     }

//     handleWindowKeyUp(e) {
//         if (isActivationKey(e)) {
//             this.setState(state => {
//                 return {
//                     isMoving: false,
//                     startMoveX: null,
//                     startMoveY: null,
//                     stopTime: Date.now(),

//                 };
//             });
//         }
//     }

//     handleWindowMouseMove(e) {
//         const { isMoving } = this.state;
        
//         if (!isMoving) {
//             if (e.metaKey || e.ctrlKey) {
//                 console.log('okay')
//                 return this.setState(state => {
//                     return {
//                         isMoving: Date.now() - state.stopTime > 200 ? true : false,
//                         currentMoveX: e.clientX,
//                         currentMoveY: e.clientY,
//                         startMoveX: e.clientX,
//                         startMoveY: e.clientY,
//                         beginAtScrollStart: this.props.focusedBegin,
//                         endAtScrollStart: this.props.focusedEnd,
//                     };
//                 });
//             }
//             return;
//         }

//         return this.setState(state => {
//             const raw = state.currentMoveY - state.startMoveY;
//             const diffY = Math.round((raw) / 3);

//             const linearDays = hours(diffY);

//             const newBegin = this.state.beginAtScrollStart - linearDays;
//             const newEnd = this.state.endAtScrollStart + linearDays;

//             this.props.timeSliceChanged(newBegin, newEnd);

//             return {
//                 currentMoveX: e.clientX,
//                 currentMoveY: e.clientY,
//             };
//         });
//     }

//     componentDidMount() {
//         window.addEventListener('mousemove', this.handleWindowMouseMove);
//         window.addEventListener('keyup', this.handleWindowKeyUp);
//     }

//     componentWillUnmount() {
//         window.removeEventListener('mousemove', this.handleWindowMouseMove);
//         window.removeEventListener('keyup', this.handleWindowKeyUp);
//     }

//     render() {
//         const {allEditHistory, visibleEditHistory, focusedBegin, focusedEnd, allHistoryBegin, allHistoryEnd} = this.props;
//         const focusedSliceStartPercent = (focusedBegin - allHistoryBegin) / (allHistoryEnd - allHistoryBegin) * 100;
//         const focusedSliceDurationPercent = (focusedEnd - focusedBegin) / (allHistoryEnd - allHistoryBegin) * 100;

//         return (
//             <div>
//                 <FocusedHistoryTimeline
//                     editHistory={editHistoryViewModel(visibleEditHistory, focusedBegin, focusedEnd)}
//                 />

//                 <AllHistoryTimeline
//                     allEditHistory={editHistoryViewModel(allEditHistory, allHistoryBegin, allHistoryEnd)}
//                     focusedSliceStartPercent={focusedSliceStartPercent}
//                     focusedSliceDurationPercent={focusedSliceDurationPercent}
//                 />
//                 <div>
//                     {new Date(focusedBegin).toString()} - {new Date(focusedEnd).toString()}
//                 </div>
//             </div>
//         );
//     }
// }

class HistoricalEventSelectorWrapper extends Component {
  constructor() {
    super();
  }

  shouldComponentUpdate(nextProps) {
    // console.log('should update', nextProps.allEditHistory.length, this.props.allEditHistory.length, nextProps.allEditHistory.length !== this.props.allEditHistory.length)
    // return nextProps.allEditHistory.length !== this.props.allEditHistory.length;
    return true;
  }

  render() {
    console.log(this.props.hasFocusedSearchResult )
    return (
      <HistoricalEventSelector
        allEditHistory={this.props.allEditHistory}
        focusedSearchResult={this.props.focusedSearchResult}
        hasFocusedSearchResult={this.props.hasFocusedSearchResult}
        searchResultIds={this.props.searchResultIds}
      />
    );
  }
}

export default HistoricalEventSelectorWrapper;
