// position it at the top of the "timeline" vertical space, an arbitrary slice of the desktop
export const getAutoDocPosition = allDocs => {
  const maxLeft = 6250;
  const betweenDocs = 20;
  const docWidth = 133;
  const maxTop = 0;

  const withinSlice = (left) => {
    return left > maxLeft - (docWidth / 2) && left < maxLeft + (docWidth / 2);
  };

  const top = allDocs.reduce((top, doc) => {
    if (withinSlice(doc.left)) {
      if (doc.top >= top) {
        return doc.top + betweenDocs;
      }
    }

    return top;
  }, maxTop);

  return {
    left: maxLeft,
    top
  };
}
