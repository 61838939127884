import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadDocumentsAndChanges, saveDoc, saveDocPosition, saveDocsPositions, deleteDoc, createDoc } from '../DocumentRepository';
import { getActiveSessionForDoc, beginTimeFromHistory, defaultEndTime } from '../services/time';
export const fetchDocumentsAndEditHistory = createAsyncThunk('documents/fetchDocuments', async () => {
  const repo = await loadDocumentsAndChanges();

  return {
    documents: repo.docs,
    editHistory: repo.changes,
    filters: {
      text: {
        terms: null,
        rawQuery: ''
      },
      time: {
        startTime: beginTimeFromHistory(repo.changes),
        endTime: defaultEndTime()
      }
    }
  };
});

const createOrAddToSession = (docId, editHistory) => {
  const [activeSession, index] = getActiveSessionForDoc(docId, editHistory);

  if (activeSession) {
    return [
      ...editHistory.slice(0, index),
      {
        ...activeSession,
        end: Date.now()
      },
      ...editHistory.slice(index + 1)
    ];
  }

  return [
    ...editHistory,
    EditSession(docId)
  ];
};

const EditSession = (docId) => {
  return {
      docid: docId,
      starttime: Date.now(), // to be renamed start
      endtime: Date.now()
  }
}

export const slice = createSlice({
  name: 'documents',
  initialState: {
    documents: [],
    editHistory: [],
    selectedDocs: [],
    status: 'idle',
    filters: {
      time: null,
      text: {
        terms: null,
        rawQuery: null
      }
    }
  },
  reducers: {
    docsSelected: (state, action) => {
      const docs = action.payload;
      const newlySelectedDocsMap = docs.reduce((acc, doc) => {acc[doc.id]=doc; return acc;}, {});
      const selectedDocsMap = state.selectedDocs.reduce((acc, doc) => {acc[doc.id]=doc; return acc;}, newlySelectedDocsMap);

      return {
        ...state,
        selectedDocs: Object.values(selectedDocsMap)
      };
    },
    docsDeselected: (state, action) => {
      const docs = action.payload;
      const deselectedDocIds = docs.map(doc => doc.id);
      const selectedDocs = state.selectedDocs.filter(doc => !deselectedDocIds.includes(doc.id));

      return {
        ...state,
        selectedDocs
      };
    },
    docsSelectedOnly: (state, action) => {
      const docs = action.payload;
      const newlySelectedDocsMap = docs.reduce((acc, doc) => {acc[doc.id]=doc; return acc;}, {});

      return {
        ...state,
        selectedDocs: Object.values(newlySelectedDocsMap)
      };
    },
    docCreated: (state, action) => {
      const newDoc = action.payload;

      const documents = {
        ...state.documents,
        [newDoc.id]: newDoc
      };

      const editHistory = createOrAddToSession(newDoc.id, state.editHistory);

      // const editHistory = [
      //   ...state.editHistory,
      //   Edit(newDoc.id)
      // ];

      const selectedDocs = [
        newDoc,
        ...state.selectedDocs
      ];

      createDoc(newDoc);

      return {
        ...state,
        documents,
        editHistory,
        selectedDocs
      };
    },
    docsMoved: (state, action) => {
      const changedDocs = action.payload;

      const documents = {
        ...state.documents
      };

      changedDocs.forEach(changedDoc => {
        documents[changedDoc.id] = changedDoc
      });

      saveDocsPositions(changedDocs);

      return {
        ...state,
        documents
      };
    },
    docMoved: (state, action) => {
      const changedDoc = action.payload;

      const documents = {
        ...state.documents,
        [changedDoc.id]: changedDoc
      };

      saveDocPosition(changedDoc);

      return {
        ...state,
        documents
      };
    },
    docContentChanged: (state, action) => {
      const changedDoc = action.payload;
      const newDoc = {
        ...state.documents[changedDoc.id],
        content: changedDoc.content,
        ev: changedDoc.ev
      };
      const documents = {
        ...state.documents,
        [changedDoc.id]: newDoc
      };

      const editHistory = createOrAddToSession(newDoc.id, state.editHistory);

      // selectedDocs should only contain IDs, not copies of the doc. For now we have this.
      const selectedDocs = state.selectedDocs.map(doc => doc.id === newDoc.id ? newDoc : doc);

      saveDoc(newDoc);

      return {
        ...state,
        documents,
        editHistory,
        selectedDocs
      };
    },
    docTitleChanged: (state, action) => {
      const changedDoc = action.payload;
      const newDoc = {
        ...state.documents[changedDoc.id],
        title: changedDoc.title
      };
      const documents = {
        ...state.documents,
        [changedDoc.id]: newDoc
      };

      const editHistory = createOrAddToSession(newDoc.id, state.editHistory);

      // selectedDocs should only contain IDs, not copies of the doc. For now we have this.
      const selectedDocs = state.selectedDocs.map(doc => doc.id === newDoc.id ? newDoc : doc);

      saveDoc(newDoc);

      return {
        ...state,
        documents,
        editHistory,
        selectedDocs
      };
    },
    docChanged: (state, action) => {
      const changedDoc = action.payload;
      const newDoc = {
        ...state.documents[changedDoc.id],
        title: changedDoc.title,
        content: changedDoc.content,
        blocks: changedDoc.blocks
      };
      const documents = {
        ...state.documents,
        [changedDoc.id]: newDoc
      };

      const editHistory = createOrAddToSession(changedDoc.id, state.editHistory);

      // selectedDocs should only contain IDs, not copies of the doc. For now we have this.
      const selectedDocs = state.selectedDocs.map(doc => doc.id === changedDoc.id ? changedDoc : doc);

      saveDoc(newDoc);

      return {
        ...state,
        documents,
        editHistory,
        selectedDocs
      };
    },
    docSettingChanged: (state, action) => {
      const changedDoc = action.payload;

      const documents = {
        ...state.documents,
        [changedDoc.id]: changedDoc
      };

      saveDoc(changedDoc);

      return {
        ...state,
        documents
      };
    },
    docDeleted: (state, action) => {
      const deletedDocId = action.payload;

      const documents = Object.keys(state.documents).reduce((docs, docId) => {
        
        if (docId !== deletedDocId) {
          docs[docId] = state.documents[docId];
        }
        return docs;
      }, {});

      const editHistory = state.editHistory.filter(edit => {
        return edit.docId !== deletedDocId;
      });

      const selectedDocs = state.selectedDocs.filter(doc => doc.id !== deletedDocId);

      deleteDoc(deletedDocId);

      return {
        ...state,
        documents,
        editHistory,
        selectedDocs
      };
    },
    searchQueryApplied: (state, action) => {
      const rawQuery = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          text: {
            terms: rawQuery.toLowerCase().split(/\s+/),
            rawQuery: rawQuery
          }
        }
      };
    },
    timeFilterApplied: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          time: action.payload
        }
      };
    },
    clearFilters: (state) => {
      return {
        ...state,
        filters: {
          text: {
            terms: null,
            rawQuery: ''
          },
          time: {
            startTime: beginTimeFromHistory(state.editHistory),
            endTime: defaultEndTime()
          }
        }
      };
    }
  },
  extraReducers: {
    [fetchDocumentsAndEditHistory.pending]: (state, action) => {
      return {
        ...state,
        status: 'loading',
      };
    },
    [fetchDocumentsAndEditHistory.fulfilled]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        status: 'succeeded'
      };
    }
  }
});

export const { docsSelected, docsDeselected, docChanged, docTitleChanged, docContentChanged, docCreated, docMoved, docsMoved, docDeleted, docSettingChanged, timeFilterApplied, docsSelectedOnly, searchQueryApplied, clearFilters } = slice.actions;

export default slice.reducer;
