import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Editor as DraftEditor, EditorState, ContentState, ContentBlock, CharacterMetadata, convertFromHTML, convertToRaw, convertFromRaw, KeyBindingUtil, RichUtils, getDefaultKeyBinding, SelectionState, Modifier } from 'draft-js';
import { Menu, Dropdown, Modal, Checkbox } from 'antd';
import { List, Map, Repeat } from 'immutable';

import { docChanged, docsDeselected, docDeleted } from '../reducers/documents';

import { BoldOutlined, UnderlineOutlined, ItalicOutlined, OrderedListOutlined, UnorderedListOutlined, CloseOutlined, MoreOutlined, ControlFilled } from '@ant-design/icons';

import 'draft-js/dist/Draft.css';
import './DraftEditor.css';

import styled from 'styled-components';

const Wrapper = styled.div`
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    transition: padding 0.2s;
    @media (min-width: 569px) {
        padding-left: 60px;
        padding-right: 60px;
    }
    @media (min-width: 1025px) {
        padding-left: 150px;
        padding-right: 150px;
    }
`;

const CloseButton = styled.div`
  position: absolute;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  top: -4px;
  right: 0px;
  color: #ccc;
  &:hover {
    color: #666;
  }
`;

const MenuTriggerWrapper = styled.div`
  position: absolute;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  top: 0px;
  right: 0px;
  color: #ccc;
  &:hover {
    color: #666;
  }
`;



const DraftInput = ({docs, isSearchVisible}) => {
    const dispatch = useDispatch();
    const editorRef = useRef(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isConfirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    useEffect(() => {
      if (!docs || docs.length === 0) {return;}
      const blocks = docs.map(doc => {
        if (doc.blocks) {
          const blocks = convertFromRaw(JSON.parse(doc.blocks)).getBlockMap().toArray().map(block => {
            block._map._root.entries.push(['__data', {docId: doc.id}]);
            return block;
          });
          blocks.unshift(new ContentBlock({
            key: Math.random(),
            type: 'customControls',
            characterList: new List([]),
            text: ''
          }));
          blocks[0]._map._root.entries.push(['__data', {docId: doc.id}]);

          return blocks;
        } else {
          return [
            new ContentBlock({
              key: Math.random(),
              type: 'customControls',
              __data: {
                docId: doc.id,
              }
            }),
            new ContentBlock({
              key: doc.id+'title',
              __data: {
                docId: doc.id,
                // isTitle: true
              },
              type: 'header-two',
              characterList: new List(Repeat(CharacterMetadata.create(), doc.title.length)),
              text: doc.title
            }),
            new ContentBlock({
              key: doc.id,
              __data: {
                docId: doc.id
              },
              type: 'paragraph',
              characterList: new List(Repeat(CharacterMetadata.create(), doc.content.length)),
              text: doc.content
            })
          ];
        }
      }).flat();  
  
      // const initialEditorState = EditorState.createWithContent();
      const contentState = ContentState.createFromBlockArray(blocks);
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-characters'
      );
      
      setEditorState(EditorState.forceSelection(
        newEditorState,
        editorState.getSelection() || SelectionState.createEmpty()
      ));
    }, [docs.length]);
  
    const getDataFieldFromBlock = (block, field) => {
      const entries = block._map._root.entries;
      if (!entries) {return null;}
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry[0] === '__data') {
          return entry[1][field];
        }
      }
    }
  
    const buildDocFromBlocks = (blocks) => {
      let docId = null;
      let currentDoc = null;
      let docs = [];
  
      for (let i = 0; i < blocks.length; i++) {
        const nextBlock = blocks[i];
        const nextDocId = getDataFieldFromBlock(nextBlock, 'docId');
        if (nextDocId && nextDocId !== docId) {
          currentDoc = {docId: nextDocId, blocks: []};
          docId = nextDocId;
          docs.push(currentDoc);
        }
        currentDoc.blocks.push(nextBlock);
      }
  
      return docs;
    };
  
    const makeEmptyTitle = (docId) => {
      return new ContentBlock({
        key: docId+'title',
        __data: {
          docId: docId,
        //   isTitle: true
        },
        type: 'docTitle',
        characterList: new List(Repeat(CharacterMetadata.create(), 0)),
        text: ''
      });
    }
  
    const makeEmptyParagraph = (docId) => {
      return new ContentBlock({
        key: docId,
        __data: {
          docId: docId
        },
        type: 'paragraph',
        characterList: new List(Repeat(CharacterMetadata.create(), 0)),
        text: ''
      });
    };
  
    const makeControlBlock = (docId) => {
        return new ContentBlock({
            key: Math.random(),
            type: 'customControls',
            __data: {
              docId: docId,
            }
          });
    };

    const getChanges = (oldBlocks, newBlocks) => {
      const oldDocs = buildDocFromBlocks(oldBlocks);
      const newDocs = buildDocFromBlocks(newBlocks);
      const oldDocIds = oldDocs.map(doc => doc.docId);
      const newDocIds = newDocs.map(doc => doc.docId);
  
      // if in oldDocs and not in newDocs, restore it
      oldDocIds.forEach((oldDocId, index) => {
        if (!newDocIds.includes(oldDocId)) {
          const oldDocWithDeletedContent = {
            ...oldDocs[index],
            blocks: [
              makeControlBlock(oldDocId),
              makeEmptyTitle(oldDocId),
              makeEmptyParagraph(oldDocId)
            ]
          };
          newDocs.splice(index, 0, oldDocWithDeletedContent);
        }
      });
  
  
      // for each new doc:
        // if blocks length changed or any block not in oldBlocks:
          // changed
      const changedDocs = newDocs.filter((newDoc, index) => {
        const oldDoc = oldDocs[index];
        
        if (newDoc.blocks.length !== oldDoc.blocks.length) {
          return true;
        }
  
        for (let i = 0; i < newDoc.blocks.length; i++) {
          if (newDoc.blocks[i] !== oldDoc.blocks[i]) {
            return true;
          }
        }
  
        return false;
      })
      
      
      return [newDocs, changedDocs];
    };
  
  
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
    const setState = (state) => {
      const newBlocks = state.getCurrentContent().getBlockMap().toArray(); // in order?
      const oldBlocks = editorState.getCurrentContent().getBlockMap().toArray(); // in order?

      const [newDocs, changedDocs] = getChanges(oldBlocks, newBlocks);
      
      if (changedDocs.length > 0) {
  
        changedDocs.forEach(changedDoc => {
          const changedDocId = changedDoc.docId;
          const docContentState = ContentState.createFromBlockArray(changedDoc.blocks.slice(1)); // don't include controls block
          const docEditorState = EditorState.push(
            editorState,
            docContentState
          );
          const rawContent = convertToRaw(docEditorState.getCurrentContent());
          const rawContentAsString = JSON.stringify(rawContent);
          const originalDoc = docs.find(doc => doc.id === changedDocId);
          const titleText = changedDoc.blocks[1].getText();

          const newDoc = {
            ...originalDoc,
            blocks: rawContentAsString,
            title: titleText,
            content: docEditorState.getCurrentContent().getPlainText()
          };
  
          dispatch(docChanged(newDoc));
        });
  
        // const fromRaw = convertFromRaw(JSON.parse(raw));
        // const blocks = fromRaw.getBlockMap().toArray();
        // console.log(blocks);
        const newBlocks = newDocs.reduce((acc, doc) => {
          return [
            ...acc,
            ...doc.blocks
          ];
        }, []).flat();
        const newContentState = ContentState.createFromBlockArray(newBlocks);
        // const newEditorState = EditorState.push(
        //   state,
        //   newContentState,
        //   // 'insert-characters'
        // );
        const newEditorState = EditorState.createWithContent(newContentState);
        setEditorState(EditorState.forceSelection(
          newEditorState,
          state.getSelection()
        ));
      } else {
        setEditorState(state);
      }
    };
  
  


    function StyleButton({onToggle, active, label, style, icon}) {
        let className = 'RichEditor-styleButton';
        if (active) {
          className += ' RichEditor-activeButton';
        }

        return (
          <span
            className={className}
            onMouseDown={e => {
            //   e.preventDefault();
              onToggle(style);
            }}>
            {icon ? icon : label}
            {/* {label}
            {icon && icon} */}
          </span>
        );
      }
      
      const BLOCK_TYPES = [
        {label: 'H1', style: 'header-one'},
        {label: 'H2', style: 'header-two'},
        {label: 'H3', style: 'header-three'},
        {label: 'H4', style: 'header-four'},
        {label: 'H5', style: 'header-five'},
        {label: 'H6', style: 'header-six'},
        {label: 'UL', style: 'unordered-list-item', icon: <UnorderedListOutlined /> },
        {label: 'OL', style: 'ordered-list-item', icon: <OrderedListOutlined /> },
      ];

      const INLINE_STYLES = [
        {label: 'Bold', style: 'BOLD', icon: <BoldOutlined /> },
        {label: 'Italic', style: 'ITALIC', icon: <ItalicOutlined /> },
        {label: 'Underline', style: 'UNDERLINE', icon: <UnderlineOutlined /> },
      ];

    const FormatBar = () => {
        let selection, currentStyle, blockType;

        try {
            selection = editorState?.getSelection();
            currentStyle = editorState?.getCurrentInlineStyle();
            blockType = editorState.getCurrentContent()?.getBlockForKey(selection.getStartKey())?.getType();
        } catch(e) {
            // console.log(e);
        }

        return (
            <div
                id='draft-editor__format-bar'
                className='RichEditor-controls'
                // style={{width: isSearchVisible ? 'calc(50% - 100px)' : 'calc(100% - 100px)', zIndex: '2'}}
                style={{width: 'calc(100% - 100px)', zIndex: '2'}}
            >
                {BLOCK_TYPES.map(type => (
                    <StyleButton
                        key={type.label}
                        icon={type.icon}
                        active={blockType ? type.style === blockType : false}
                        label={type.label}
                        onToggle={blockType => {
                            try {
                                const newState = RichUtils.toggleBlockType(editorState, blockType);
                                setEditorState(newState);
                            } catch (e) {}
                        }}
                        style={type.style}
                    />
                ))}

                {INLINE_STYLES.map(type => (
                    <StyleButton
                        key={type.label}
                        icon={type.icon}
                        active={currentStyle ? currentStyle.has(type.style) : false}
                        label={type.label}
                        onToggle={inlineStyle => {
                            try {
                                const newState = RichUtils.toggleInlineStyle(
                                editorState,
                                inlineStyle,
                                );
                                setEditorState(newState);
                            } catch (e) {}
                        }}
                        style={type.style}
                    />
                ))}
            </div>
        );
    };

    const ButtonPanel = styled.div`
        display: block;
        height: 20px;
        background-color: red;
        position: absolute;
        right: 0px;
        top: 0px;
    `;

    function ControlsRenderer(props) {
        const docId = getDataFieldFromBlock(props.blockProps.contentBlock, 'docId');
        const doc = docs.find(doc => doc.id === docId);
        if (!doc) {return <div />}
        const documentClosed = (e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(docsDeselected([doc]));
        }
        const confirmDelete = () => {
            // console.log('delete', doc.id)
            setConfirmDeleteVisible(false);
            dispatch(docDeleted(doc.id));
          };
        const menu = (
            <Menu>
                {/* <Menu.Item>
                <Checkbox checked={doc.settings?.wrap || false} onChange={() => {}}>Wrap</Checkbox>
                </Menu.Item> */}
                
                {/* <Menu.Divider /> */}
                
                <Menu.Item danger>
                <a onMouseDown={() => setConfirmDeleteVisible(true)}>Delete</a>
                </Menu.Item>
            </Menu>
            );

        return (
            <div className='custom-control-bar' style={{position: 'relative', marginTop: '15px', paddingTop: '10px', borderTop: '#BF7E0264 1px solid', zIndex: '9'}}>
                <ButtonPanel id="buttonPanel">
                    <CloseButton onMouseDown={documentClosed}><CloseOutlined /></CloseButton>
        
                    {/* <Modal title="Confirm Delete" visible={isConfirmDeleteVisible} onOk={confirmDelete} onCancel={() => setConfirmDeleteVisible(false)} okButtonProps={{style: {background: 'red'}}}>
                        <p>{`Are you sure you want to delete ${doc.title}?`}</p>
                    </Modal>

                    <MenuTriggerWrapper>
                        <Dropdown overlay={menu} trigger={['hover']}>
                        <div>
                            <MoreOutlined />
                        </div>
                        </Dropdown>
                    </MenuTriggerWrapper> */}
                </ButtonPanel>
            </div>
            
        )
    }

    function onTab(e) {
        setState(RichUtils.onTab(e, editorState, 6));
    }

    function pasteHandler(text, html, editorState) {
        // if we allow raw pasting, the docIds in the __data property will also be pasted across docs, breaking uuid order assumptions in the change map
        if (html) {
            const blocksFromHTML = convertFromHTML(html);
            const stateFromBlocks = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            const newContent = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                stateFromBlocks.getBlockMap()
            );

            setState(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            ));

            return true;
        }
        return false;
    }
      

    const handleKeyCommand = useCallback(
        (command, editorState) => {
        //   console.log(command)
        //   if (command === 'myeditor-save') {
        //     console.log('save')  
        //     return 'handled';
        //   }
        console.log(command)
          if (command === 'backspace') {
            return 'not-handled';
          }

          const newState = RichUtils.handleKeyCommand(editorState, command);

          if (newState) {
            setEditorState(newState);
            return 'handled';
          }

          return 'not-handled';
        },
        [editorState, setState]
      );

    const customBlockRenderer = (contentBlock) => {
        const type = contentBlock.getType();
        
        if (type === 'customControls') {
            
          return {
            component: ControlsRenderer,
            editable: false,
            props: {
                contentBlock
            },
          };
        }
      };
      
    const keyBindingFn = (e) => {
        // if (e.keyCode === 83 /* `S` key */ && KeyBindingUtil.hasCommandModifier(e)) {
        //     return 'myeditor-save';
        // }
        return getDefaultKeyBinding(e);
    }
  
    return (
    //   <div style={{padding: '10px', paddingTop: '15px'}}>
    <Wrapper>
        {/* <BlockStyleControls
            editorState={editorState}
            onToggle={blockType => {
                const newState = RichUtils.toggleBlockType(editorState, blockType);
                setEditorState(newState);
            }}
            /> */}
            {/* <InlineStyleControls
            editorState={editorState}
            onToggle={inlineStyle => {
                const newState = RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle,
                );
                setEditorState(newState);
            }}
        /> */}
        <FormatBar></FormatBar>
        <DraftEditor
            editorState={editorState}
            onChange={setState}
            // spellCheck={true}
            handleKeyCommand={handleKeyCommand}
            ref={editorRef}
            blockRendererFn={customBlockRenderer}
            handlePastedText={pasteHandler}
            onTab={onTab}
            keyBindingFn={keyBindingFn}
        />
      </Wrapper>
    );
};

export default DraftInput;