import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { debounce } from '../services/utils';
import { getYCoordinateFromEvent } from '../services/eventHandlers';
import { isMobile } from '../services/utils';



// function updateBackground(runningTotalHeight) {
//   let backgroundPercent = '100';
//   if (runningTotalHeight < (window.innerHeight - 35)) {
//     backgroundPercent = runningTotalHeight / (window.innerHeight - 35) * 100;
//   }
//   const editor = document.getElementById('editor-panel__content');
//   const wrapper = document.getElementById('minimap-wrapper');

//   html2canvas(editor, {backgroundColor: '#f9f9f9'}).then(function(canvas) {
//     wrapper.style.backgroundImage = `url(${canvas.toDataURL()})`
//     wrapper.style.backgroundSize = `500% ${backgroundPercent}%`;
//     wrapper.style.backgroundRepeat = 'no-repeat';
//   });
// }

// const debounceUpdateBackground = debounce(updateBackground, 1000);

// window.updateBackground = updateBackground;


const Wrapper = styled.div`
  position: fixed;
  top: 35px;
  bottom: 0px;
  width: 100px;
  border-left: #ddd 1px solid;
  cursor: pointer;
  opacity: 1;
  background: #f9f9f9;
`;

const MiniMapDoc = styled.div`
  border-bottom: orange 0px solid;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Viewport = styled.div`
  position: absolute;
  width: 100%;
  background: rgb(200,200,230);
  opacity: 0.3;
  cursor: pointer;
`;

const windowInnerHeight = () => {
  return window.innerHeight - 35;
}

class MiniMap extends Component {
  constructor() {
    super();
    this.state = {
      editorTiles: [],
      isDragging: false,
      scrollTop: 0
    };
    this.wrapperRef = createRef();
    this.measure = this.measure.bind(this);
    this.setScrollPosition = this.setScrollPosition.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.setScrollPositionFromMouse = this.setScrollPositionFromMouse.bind(this);
  }
  
  measure() {
    // custom-control-bar
    const editorPanel = document.querySelectorAll('.editor-panel')[0];
    // const editors = document.querySelectorAll('.editor');
    const editors = document.querySelectorAll('.custom-control-bar');
    if (!editorPanel || editors.length === 0) {this.setState({editorTiles: []}); return;}

    // let runningTotalHeight = 0;
    let lastEditorTop = editors[0].offsetTop;
    if (editors.length !== this.props.docs.length) {return;}
    
    const editorTiles = Array.prototype.map.call(editors, (editor, index) => {
      const nextBoundaryTop = index < editors.length - 1 ? editors[index + 1].offsetTop : editorPanel.clientHeight;
      const editorHeight = nextBoundaryTop - lastEditorTop;
      // const heightPercent = editor.clientHeight / editorPanel.clientHeight * 100;
      const heightPercent = editorHeight / editorPanel.clientHeight * 100;
      const doc = this.props.docs[index];

      lastEditorTop = nextBoundaryTop;
      // runningTotalHeight += editor.clientHeight;

      return {
        title: doc.title,
        content: doc.content,
        lineCount: Math.max(doc.content.split(/\n/).length + 5, 1),
        wrap: doc.settings?.wrap ? true : false,
        heightPercent
      }
    });

    this.setScrollPosition();

    this.setState({
      editorTiles
    });

    // debounceUpdateBackground(runningTotalHeight);
  }

  componentWillReceiveProps(props, nextProps) {
    setTimeout(this.measure, 100);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.setScrollPosition, {passive: false});
    window.addEventListener('resize', this.setScrollPosition, {passive: false});
    window.addEventListener('mousemove', this.handleMouseMove, {passive: false});
    window.addEventListener('mouseup', this.handleMouseUp, {passive: false});

    this.wrapperRef.current.addEventListener('mousedown', this.handleMouseDown, {passive: false});
    this.wrapperRef.current.addEventListener('touchstart', this.handleMouseDown, {passive: false});

    window.addEventListener('touchmove', this.handleMouseMove, {passive: false});
    window.addEventListener('touchend', this.handleMouseUp, {passive: false});

    window.addEventListener('scroll', e => {if (this.state.isDragging) {e.preventDefault(); e.stopPropagation(); return false;}}, {passive: false});

    this.wrapperRef.current.addEventListener('scroll', e => {e.preventDefault(); e.stopPropagation();}, {passive: false});

    setTimeout(this.measure);
  }

  setScrollPosition() {
    const maxScroll = document.body.scrollHeight - (window.innerHeight);
    const currentScroll = window.pageYOffset;
    const scrollTopPercent = currentScroll / maxScroll;
    const viewportHeight = Math.floor(window.innerHeight / document.body.scrollHeight * window.innerHeight);
    const scrollTop = scrollTopPercent * (windowInnerHeight() - viewportHeight);

    this.setState({
      viewportHeight,
      scrollTop
    });
  }

  handleMouseMove(e) {
    if (this.state.isDragging) {
      e.preventDefault();
      this.setScrollPositionFromMouse(e);
    }
  }

  setScrollPositionFromMouse(e) {
    
    e.preventDefault();
    const maxScroll = windowInnerHeight();
    
    const percent = (getYCoordinateFromEvent(e) - 35) / maxScroll;

    const viewportOffset = this.state.viewportHeight / window.innerHeight / 2;
    const scrollTop = (percent * document.body.scrollHeight) - (viewportOffset * document.body.scrollHeight);

    window.scrollTo(0, scrollTop);
  }

  handleMouseUp(e) {
    setTimeout(() => {
      this.setState({
        isDragging: false
      });
    })
  }

  handleMouseDown(e) {
    // if (e.target.id === 'minimap-wrapper' || e.target.classList.contains('minimap-viewport') || e.target.classList.contains('notebody') || e.target.classList.contains('notetitle')) {
      e.stopPropagation();
      e.preventDefault(); // don't select text
      this.setScrollPositionFromMouse(e);
      this.setState({
        isDragging: true
      });
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setScrollPosition);
    window.removeEventListener('resize', this.setScrollPosition);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('touchmove', this.handleMouseMove);
    window.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('touchstart', this.handleMouseDown);
    window.removeEventListener('mousedown', this.handleMouseDown);
  }

  render() {
    return (
      <Wrapper
        id='minimap-wrapper'
        style={{
          boxShadow:'rgba(0, 0, 0, 0.1) -4px -4px 34px',
          zIndex: 3,
          // right: this.props.isSearchVisible ? 'calc(100% - 100px)' : '0px'
          right: '0px'
        }}
        ref={this.wrapperRef}
      >
        {
          this.state.editorTiles.map((editorTile, i) => {
            const lineHeight =  Math.max(
                                  Math.min(
                                    (editorTile.heightPercent / 100 * windowInnerHeight()) / editorTile.lineCount,
                                    12
                                  ),
                                  2
                                );
            return (
              <MiniMapDoc
                key={i}
                style={{
                  height: `${editorTile.heightPercent}%`,
                  padding: '0px 8px',
                  borderTop: '#eee 1px solid',
                  // borderBottom: 'gray 1px dashed'
                }}
              >
                <p
                  className={'notetitle'}
                  style={{
                  width: '10000%',
                  fontSize: '12px',
                  marginBottom: '0px',
                  cursor: 'pointer',
                  letterSpacing: '-0.8px',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                }}>{editorTile.title}</p>
              
              {/* <textarea
                className={'notebody'}
                style={{
                  border: 'none',
                  height: 'calc(100% - 18px)',
                  width: editorTile.wrap ? '200%' : '50000%',
                  color: 'rgb(190,190,190)',
                  backgroundColor: '#f9f9f9',
                  lineHeight: `${lineHeight}px`,
                  fontSize: `${Math.max(lineHeight-5, 2)}px`,
                  letterSpacing: '-0.5px',
                  // fontSize: `3px`,
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}
                value={editorTile.content}
                readOnly
              /> */}
              </MiniMapDoc>
            );
          })
        }
        {this.state.editorTiles.length > 0 && <Viewport
          className={`minimap-viewport`}
          style={{
            height: `${this.state.viewportHeight}px`,
            top: `${this.state.scrollTop || 0}px`
          }}
        />}
        
      </Wrapper>
    );
  }
}

export default MiniMap;