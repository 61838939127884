import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { docsSelected, docsDeselected, docsSelectedOnly, searchQueryApplied } from '../reducers/documents';
import HistoricalEventSelector from './HistoricalEventSelector';
import DocDesktop from './DocDesktop';
import SessionHistory from './SessionHistory';
import { isMobile } from '../services/utils';

// const Timeline = styled.div`
//     display: block;
//     width: 30px;
//     border: black 1px solid;
//     margin-bottom: 10px;
//     position: absolute;
//     top: 50px;
//     bottom: 35px;
//     overflow: hidden;
//     right: 10px;
// `;

const PanelContainer = styled.div`
    background-color: #bbb;
    position: fixed;
    top: 35px;
    // min-width: ${isMobile ? '100%' : '520px'};
    // width: ${isMobile ? '100%' : '50%'};
    width: calc(100% - 100px);
    left: 0px;
    bottom: 0px;
    opacity: 1;
    z-index: 100;
    padding: 10px;
    height: 100%;
`;

const TopSearchResult = styled.div`
    position: absolute;
    display: block;
    top: 11px;
    right: 7px;
    padding: 3px 7px;
    height: 24px;
    font-size: 12px;
`;

const TopSearchResultTitle = styled.div`
    display: inline-block;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #BF7E0238;
    margin-left: 5px;
    padding: 2px 5px;
`;

const applyTimeFilter = (allEditHistory, timeFilter) => {
  const {startTime, endTime} = timeFilter;

  const editsInFilter = allEditHistory.filter(edit => {
    const datetime = new Date(edit.starttime).getTime();

    return datetime >= startTime && datetime <= endTime;
  });

  const docIdsForEdits = editsInFilter.reduce((acc, edit) => {
    acc[edit.docid] = true;
    return acc;
  }, {});
  return Object.keys(docIdsForEdits);
};

const applyTextFilter = (docList, terms) => {
  const filteredDocs = docList.filter(doc => {
    return terms.every(term => doc.title.toLowerCase().includes(term) || doc.content.toLowerCase().includes(term))
  });
  return filteredDocs.map(doc => doc.id);
};

const filterInDocs = (allDocs, allEditHistory, filters) => {
  let filteredInDocIds = allDocs.map(doc => doc.id);

  if (filters.time) {
    filteredInDocIds = applyTimeFilter(allEditHistory, filters.time);
  }

  if (filters.text.terms && filters.text.terms.length > 0) {
    const docsForIds = allDocs.filter(doc => filteredInDocIds.includes(doc.id));
    filteredInDocIds = applyTextFilter(docsForIds, filters.text.terms);
  }

  return filteredInDocIds;
};

const getSearchResultsOrderByLastEditDesc = (allDocs, allEditHistory, filteredInDocIds) => {
  
  const docsById = allDocs.reduce((acc, doc) => {
    acc[doc.id] = doc;
    return acc;
  }, {});
  
  const lastEditByDoc = allEditHistory.reduce((acc, edit) => {
    acc[edit.docid] = new Date(edit.endtime);
    return acc;
  }, {});

  const orderedSelectedIds = filteredInDocIds.filter(docId => docsById[docId]).map(docId => {
    return {
      docId,
      edit: lastEditByDoc[docId],
      title: docsById[docId].title
    };
  });

  return orderedSelectedIds.sort((a, b) => {return a.edit < b.edit});
};


const SearchPanel = () => {
  const [allDocs, selectedDocIds, allEditHistory, filters] = useSelector(state => {
    
    return [
      Object.values(state.documents.documents),
      state.documents.selectedDocs.map(doc => doc.id),
      state.documents.editHistory,
      state.documents.filters
    ];
  });

  
  const [focusedSearchResultIndex, setFocusedSearchResultIndex] = useState(0);
  // console.log(focusedSearchResultIndex)

  const filteredInDocIds = filterInDocs(allDocs, allEditHistory, filters);

  // const focusedResult = selectedDocIds.map(id => allDocs[id])
  
  // console.log(allEditHistory, allDocs);
  const orderedSearchResults = getSearchResultsOrderByLastEditDesc(allDocs, allEditHistory, filteredInDocIds);
  // console.log(orderedSearchResults)
  // console.log(orderedSearchResults, focusedSearchResultIndex, orderedSearchResults[focusedSearchResultIndex].title)
  // console.log(orderedSearchResults[focusedSearchResultIndex].title)

  // const [focusedBegin, setFocusedBegin] = useState(beginTimeFromHistory(allEditHistory));
  // const [focusedEnd, setFocusedEnd] = useState(Date.now() + days(1));

  const dispatch = useDispatch();

  // const handleTimeSliceChange = (focusedBegin, focusedEnd) => {
  //   setFocusedBegin(Math.max(focusedBegin, allHistoryBegin));
  //   setFocusedEnd(Math.min(focusedEnd, allHistoryEnd));
  // }

  // const visibleEditHistory = getEditHistoryBetween(focusedBegin, focusedEnd, allEditHistory);

  const selectAll = (allDocs, filteredInDocIds) => {
    dispatch(docsSelectedOnly(
      allDocs.filter(doc => filteredInDocIds.includes(doc.id))
    ));
  };

  const formatDate = (date) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short', day: '2-digit' });
    const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date);

    return `${month} ${day}, '${year}`;
  }

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter' && e.getModifierState('Meta')) {
  //     selectAll(allDocs, filteredInDocIds);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleKeyDown]);

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const hasFocusedSearchResult = !!filters.text.rawQuery && !!orderedSearchResults[focusedSearchResultIndex];
  const focusedSearchResult = orderedSearchResults[focusedSearchResultIndex];

  return (
    <PanelContainer
      onScroll={(e) => {e.stopPropagation(); e.preventDefault();}}
      onWheel={() => console.log('wheelies')}
    >
        <input
          style={{
            height: '30px',
            marginBottom: '10px',
            padding: '2px',
            width: '100%',
            fontSize: '13px',
            outline: 'none'
          }}
          ref={inputRef}
          value={filters.text.rawQuery}
          placeholder='Search'
          onChange={(e) => {
            setFocusedSearchResultIndex(0);
            dispatch(searchQueryApplied(e.target.value))}
          }
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp') {
              const newIndex = focusedSearchResultIndex - 1;
              setFocusedSearchResultIndex(newIndex >= 0 ? newIndex : 0);
              e.stopPropagation();
              e.preventDefault();
              return;
            }
            if (e.key === 'ArrowDown') {
              const newIndex = focusedSearchResultIndex + 1;
              setFocusedSearchResultIndex(newIndex < filteredInDocIds.length ? newIndex : filteredInDocIds.length - 1);
              e.stopPropagation();
              e.preventDefault();
              return;
            }
            if (e.key === 'Enter') {
              // selectAll(allDocs, filteredInDocIds);
              if (!orderedSearchResults[focusedSearchResultIndex]) {
                return;
              }
              const {docId} = orderedSearchResults[focusedSearchResultIndex];
              const doc = allDocs.find(doc => doc.id === docId);
              dispatch(docsSelected([doc]));
              return;
            }}
          }
        />
        {hasFocusedSearchResult && <TopSearchResult>
            <TopSearchResultTitle>{`${orderedSearchResults[focusedSearchResultIndex].title}`}</TopSearchResultTitle>
            <TopSearchResultTitle style={{background: '#025EBF38'}}>{`${focusedSearchResultIndex + 1}/${orderedSearchResults.length}`}</TopSearchResultTitle>
            <TopSearchResultTitle style={{background: '#4FBF0238'}}>{`${formatDate(focusedSearchResult.edit)}`}</TopSearchResultTitle>
          </TopSearchResult>}
        {/* <Timeline></Timeline> */}
        <DocDesktop
            allDocs={allDocs}
            filteredInDocIds={filteredInDocIds}
            selectedDocIds={selectedDocIds}
            selectDocs={(docs) => {dispatch(docsSelected(docs));window.scrollTo(0,0)}}
            deselectDocs={(docs) => {dispatch(docsDeselected(docs));}}
            focusedSearchResult={focusedSearchResult}
            hasFocusedSearchResult={hasFocusedSearchResult}
        />

        <div style={{position: 'absolute', bottom: '50px', left: '10px', right: '10px', height: '60px'}}>
            <HistoricalEventSelector
                allEditHistory={allEditHistory}
                focusedSearchResult={focusedSearchResult}
                hasFocusedSearchResult={hasFocusedSearchResult}
                searchResultIds={orderedSearchResults.map(result => result.docId)}
                // focusedBegin={focusedBegin}
                // focusedEnd={focusedEnd}
                // timeSliceChanged={handleTimeSliceChange}
                // visibleEditHistory={visibleEditHistory}
            />
        </div>

        {/* <button onClick={() => selectAll(allDocs, filteredInDocIds)}>Open All</button> */}
        
        
        {/* <SessionHistory
          allEditHistory={allEditHistory}
          allDocs={allDocs}
        /> */}
    </PanelContainer>
  );
}

export default SearchPanel;
