import React, { useEffect, useState } from 'react';
import { verifyEmail } from '../UserRepository.js';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SignInWrapper from '../components/SignInWrapper';

const parseQueryParams = () => {
    return window.location.search
        .slice(1)                                   // remove '?'
        .split('&')                                 // separate distinct key/value pairs
        .reduce((acc, stringpair) => {              // map into json obj
            const pieces = stringpair.split('=');
            acc[pieces[0]] = pieces[1];
            return acc}
        , {});
}

const verificationStatuses = {
    TRYING: 'trying',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
};

const VerifyEmail = () => {
    const { email, token } = parseQueryParams();
    const [status, setStatus] = useState(verificationStatuses.trying);
    
    useEffect(() => {
        if (!email || !token) {
            return setStatus(verificationStatuses.FAILED);
        }
        verifyEmail(email, token)
            .then(() => {
                setStatus(verificationStatuses.SUCCEEDED);
            })
            .catch(() => {
                setStatus(verificationStatuses.FAILED);
            });
    }, []);

    return (
        <SignInWrapper>
            {status === verificationStatuses.TRYING && <div><span>Verifying email address</span> <LoadingOutlined /></div>}
            {status === verificationStatuses.FAILED && <div><span>An error was encountered when validating the email address.</span></div>}
            {status === verificationStatuses.SUCCEEDED && <div><span>Email address validated successfully. <Link to='/signin'>Sign in here.</Link></span></div>}
        </SignInWrapper>
    );
};

export default VerifyEmail;
