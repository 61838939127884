import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router';
import { signUp } from '../UserRepository';
import SignInWrapper from '../components/SignInWrapper';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Logo';
import { generateKeyAndEncryptWithPasswordAndStore } from '../services/crypto';

const FormRow = styled.div`
  margin-top: 10px;
`;

const SignUpButtonStyled = styled.button`
  text-align: center;
  min-width: 100px;
  cursor: pointer;
`;

const signUpStates = {
  signingup: 'signingup',
  idle: 'idle',
  error: 'error',
  signupsuccess: 'signupsuccess'
};

const SignUp = () => {
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [signUpState, setSignUpState] = useState(signUpStates.idle);
  
  const SignUpButton = ({submit, signUpState}) => {
    const label = signUpState === signUpStates.signingup ? <LoadingOutlined /> : 'Create account';
    const disabled = signUpState === signUpStates.signingup || (!signUpEmail || !signUpPassword);
    
    return <SignUpButtonStyled disabled={disabled} onClick={submit}>{label}</SignUpButtonStyled>;
  }
  
  const dispatch = useDispatch();

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      submitSignUp();
    }
  };

  const submitSignUp = () => {
    setSignUpState(signUpStates.signingup);

    const encryptedKey = generateKeyAndEncryptWithPasswordAndStore(signUpPassword);

    signUp(signUpEmail, signUpPassword, encryptedKey)
        .then(r => {
            console.log(r)
            setSignUpState(signUpStates.signupsuccess);
        })
        .catch(e => {
            setSignUpState(signUpStates.error);
        });
  };

  return (
    <div>
        <Link to="/" style={{position: 'absolute', top: '10px', left: '10px'}}><Logo /></Link>
        <SignInWrapper>
            
            {signUpState !== signUpStates.signupsuccess && <div>
                <h2>Create account</h2>
                <div><input style={{borderRadius: '5px', border: '1px solid #e6e6e6', padding: '12px 16px'}} placeholder='Email address' type="text" onKeyUp={checkSubmit} value={signUpEmail} onChange={event => setSignUpEmail(event.target.value)} /></div>
                <div><input style={{marginTop: '10px', borderRadius: '5px', border: '1px solid #e6e6e6', padding: '12px 16px'}} placeholder='Password' type="password" onKeyUp={checkSubmit} value={signUpPassword} onChange={event => setSignUpPassword(event.target.value)} /></div>
                <FormRow><SignUpButton signUpState={signUpState} submit={submitSignUp}>Sign up</SignUpButton></FormRow>
                {signUpState === signUpStates.error && <FormRow><WarningOutlined />There was an error trying to sign up. Please try again.</FormRow>}
            </div>}
            {signUpState === signUpStates.signupsuccess && <Redirect to="/post-signup" />}  
                
            <div style={{borderTop: 'gray 1px solid', marginTop: '40px'}}>
                <p style={{marginTop: '10px', marginBottom: '0px'}}>Already have an account?</p>
                <Link to='/signin'>Sign in</Link>
            </div>
        </SignInWrapper>
    </div>
  );
};

export default SignUp;
