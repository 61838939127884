import React from 'react';
import styled from 'styled-components';

const EventRecord = styled.div`
    display: block;
    width: 4px;
    position: absolute;
    border-radius: 50px;
    height: 4px;
`;

export default EventRecord;
