import React from 'react';
import App from './App';
import Landing from './pages/Landing';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import VerifyEmail from './pages/VerifyEmail';
import Settings from './pages/Settings';
import PostSignUp from './pages/PostSignUp';
import { BrowserRouter, Route } from 'react-router-dom';

export default () => (
  <BrowserRouter>
    <Route path="/" exact component={Landing} />
    <Route path="/signin" exact component={SignIn} />
    <Route path="/signup" exact component={SignUp} />
    <Route path="/app" component={App} />
    <Route path="/post-signup" component={PostSignUp} />
    <Route path="/verify-email" component={VerifyEmail} />
    <Route path="/account/settings" component={Settings} />
  </BrowserRouter>
  );
