import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import { getXCoordinateFromEvent, getYCoordinateFromEvent } from '../services/eventHandlers';
import { useSelector } from 'react-redux';

const DesktopMiniMapContainer = styled.div`
  display: block;
  background: #FBA500;
  border: black 0px solid;
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  overflow: hidden;
  width: 240px;
  height: 180px;
`;

const SearchTitle = styled.div`
  position: relative;
  top: -6px;
  left: 7px;
  display: block;
  font-size: 10px;
  font-weight: bold;
  max-width: 50px;
  z-index: 9999;
`;


let mouseDownOffsetX = 0;
let mouseDownOffsetY = 0;

const scale = 0.0375;

const DesktopMiniMap = React.forwardRef(({allDocs, selectedDocIds, filteredInDocIds, handlePan, focusedSearchResult, hasFocusedSearchResult}, ref) => {
  const [isPanning, setIsPanning] = useState(false);
  const containerRef = useRef();
  const searchQuery = useSelector(state => {
    return state.documents.filters.text.rawQuery;
  });

  const getRelativeCoordsFromEvent = (event) => {
    event.preventDefault();
    
    const mouseX = getXCoordinateFromEvent(event) - containerRef.current.getBoundingClientRect().left;
    const mouseY = getYCoordinateFromEvent(event) - containerRef.current.getBoundingClientRect().top;

    return {x: mouseX, y: mouseY};
  }

  const handlePanEvent = (event) => {
    const {x, y} = getRelativeCoordsFromEvent(event);

    handlePan({
      x: x - mouseDownOffsetX,
      y: y - mouseDownOffsetY
    });
  }

  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsPanning(true);

    handlePanEvent(event);
  };

  const handleViewportMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const middleX = event.target.getBoundingClientRect().left + (event.target.getBoundingClientRect().width / 2);
    const middleY = event.target.getBoundingClientRect().top + (event.target.getBoundingClientRect().height / 2)

    mouseDownOffsetX = getXCoordinateFromEvent(event) - middleX;
    mouseDownOffsetY = getYCoordinateFromEvent(event) - middleY;

    setIsPanning(true);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isPanning) {
      handlePanEvent(event);
    }
  };

  const handleMouseUp = (event) => {
    if (isPanning) {
      setIsPanning(false);
      handlePanEvent(event);
      mouseDownOffsetX = 0;
      mouseDownOffsetY = 0;
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
    };
  }, [handleMouseUp, handleMouseMove]);

  const hasTextQuery = !/^\s*$/.test(searchQuery);

  return (
    <DesktopMiniMapContainer
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
    >

      {hasTextQuery && allDocs.filter((doc => {
        const isVisible = filteredInDocIds.includes(doc.id);
        return isVisible;
      })).map((doc) => {
        return (
          <div
            key={doc.id}
            style={{
              position: 'absolute',
              display: 'block',
              left: (doc.left * scale) - 4,
              top: (doc.top * scale) - 4,
              zIndex: 0
            }}
          >
            {/* <div
              style={{
                position: 'relative',
                display: 'block',
                height: '20px',
                width: '20px',
                top: 0,
                left: 0,
                opacity: '0.2',
                background: 'green',
                borderRadius: '50px'
              }}
            ></div>
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '20px',
                width: '20px',
                top: -20,
                left: 0,
                opacity: '1',
                zIndex: 0,
                border: 'green 1px solid',
                borderRadius: '50px'
              }}
            ></div> */}
            <div
              style={{
                position: 'relative',
                display: 'block',
                height: '11px',
                width: '11px',
                top: 0,
                left: 0,
                opacity: '0.5',
                background: hasFocusedSearchResult && focusedSearchResult.docId === doc.id ? 'blue' : 'red',
                borderRadius: '50px'
              }}
            ></div>
            {/* <div
              style={{
                position: 'relative',
                display: 'block',
                height: '9px',
                width: '9px',
                top: 0,
                left: 0,
                opacity: '0.5',
                background: 'red',
                borderRadius: '50px'
              }}
            ></div> */}
          </div>
        );
      })}

      {allDocs.map((doc) => {
        const isVisible = filteredInDocIds.includes(doc.id);
        const isSelected = selectedDocIds.includes(doc.id);
        const isFocusedSearchResult = hasFocusedSearchResult && doc.id === focusedSearchResult.docId;
        return (
          <div
            key={doc.id}
            id={doc.id}
            style={{
              position: 'absolute',
              display: 'block',
              left: doc.left * scale,
              top: doc.top * scale,
              whiteSpace: 'nowrap',
              height: '3px',
              width: '3px',
              opacity: isVisible ? 1 : 0.1,
              zIndex: isFocusedSearchResult ? 4 : isVisible ? isSelected ? 3 : 2 : 1,
              background: isVisible ? isSelected ? 'green' : 'white' : 'black',
            }}
          >{isFocusedSearchResult && <SearchTitle>{doc.title}</SearchTitle>}</div>
        );
      })}

      <div
        ref={ref}
        onMouseDown={handleViewportMouseDown}
        onTouchStart={handleViewportMouseDown}
        style={{
          position: 'absolute',
          background: `cornflowerblue`,
          opacity: 0.5,
          zIndex: 3,
        }}
      />
    </DesktopMiniMapContainer>
  );
});

export default DesktopMiniMap;
