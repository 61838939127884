import styled from 'styled-components';

const SignInWrapper = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
`;

export default SignInWrapper;
