export const minutes = n => n * 60 * 1000;
export const hours = n => n * 60 * minutes(1);
export const days = n => n * 24 * hours(1);
export const weeks = n => n * days(7);

const editSessionLength = minutes(15);

export const beginTimeFromHistory = allEditHistory => {
  return allEditHistory.length > 0 ? new Date(allEditHistory[0].starttime).getTime() - days(1) : Date.now() - days(1);
}

export const defaultEndTime = () => {
  return Date.now() + weeks(2);
}

export const getActiveSessionForDoc = (docId, allEditHistory) => {
  let now = Date.now();
  let session;

  for (let i = allEditHistory.length - 1; i >= 0; i--) {
    session = allEditHistory[i];

    if (session.docId === docId) {
      if (now - session.end > editSessionLength) {
        return [null, null];
      }

      return [session, i];
    }
  }

  return [null, null];
}