import React, { Component, useState } from 'react';
import { Menu, Dropdown, Modal, Checkbox } from 'antd';
import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { useSelector, useDispatch } from 'react-redux';
import {UnControlled as CodeMirror} from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';

import 'codemirror/keymap/sublime';
import styled from 'styled-components';
import { docTitleChanged, docContentChanged, docsDeselected, docDeleted, docSettingChanged } from '../reducers/documents';

const Wrapper = styled.div`
  // border-bottom: #e1e1e1 1px solid;
  padding: 10px 0px 10px 0px;
  position: relative;
`;

const TitleWrapper = styled.div`
  padding: 0px 10px 0px 10px;
`;

const TitleInput = styled.input`
  border: none;
  font-size: 20px;
  outline: none;
  width: calc(100% - 53px);
  color: rgb(32, 43, 51);
  background: #f9f9f9;
`;

const CloseButton = styled.div`
  position: absolute;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  top: 0px;
  right: 35px;
  color: #ccc;
  &:hover {
    color: #666;
  }
`;

const MenuTriggerWrapper = styled.div`
  position: absolute;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  top: 0px;
  right: 0px;
  color: #ccc;
  &:hover {
    color: #666;
  }
`;


const Editor = ({docId}) => {
  const doc = useSelector(state => {
    return state.documents.documents[docId];
  });

  const [isConfirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  const dispatch = useDispatch();

  const documentClosed = () => {
    dispatch(docsDeselected([doc]));
  }

  const contentChanged = (CM, edit, newValue) => {
    dispatch(docContentChanged({
      ...doc,
      content: newValue
    }));
  };

  const titleChanged = (e) => {
    dispatch(docTitleChanged({
      ...doc,
      title: e.target.value
    }));
  };

  const confirmDelete = () => {
    setConfirmDeleteVisible(false);
    dispatch(docDeleted(doc.id));
  };

  const wrapChanged = (e) => {
    const changedDoc = {
      ...doc
    };

    changedDoc.settings = doc.settings ? {...doc.settings} : {wrap: false};

    changedDoc.settings.wrap = e.target.checked;

    dispatch(docSettingChanged(changedDoc));
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Checkbox checked={doc.settings?.wrap || false} onChange={wrapChanged}>Wrap</Checkbox>
      </Menu.Item>
      
      <Menu.Divider />
      
      <Menu.Item danger>
        <a onClick={() => setConfirmDeleteVisible(true)}>Delete</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper className="editor">
      <TitleWrapper>
        <TitleInput type="text" onChange={titleChanged} value={doc.title} />
      </TitleWrapper>
      <CloseButton onClick={documentClosed}><CloseOutlined /></CloseButton>
      
      <Modal title="Confirm Delete" visible={isConfirmDeleteVisible} onOk={confirmDelete} onCancel={() => setConfirmDeleteVisible(false)} okButtonProps={{style: {background: 'red'}}}>
        <p>{`Are you sure you want to delete ${doc.title}?`}</p>
      </Modal>

      <MenuTriggerWrapper>
        <Dropdown overlay={menu} trigger={['click']}>
          <div onClick={e => e.preventDefault()}>
            <MoreOutlined />
          </div>
        </Dropdown>
      </MenuTriggerWrapper>

      <CodeMirrorWrapper
        doc={doc}
        contentChanged={contentChanged}
      />
    </Wrapper>
  );
};

export default Editor;

const hasChangedRenderSetting = (prevDoc, nextDoc) => {
  // render config has been created
  if (nextDoc.settings && !prevDoc.settings) {
    return true;
  }

  if (!nextDoc.settings) {
    return false;
  }

  return Object.keys(nextDoc.settings).some(key => {
    return prevDoc.settings[key] !== nextDoc.settings[key];
  });
}

class CodeMirrorWrapper extends Component {
  constructor() {
    super();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.doc.id !== this.props.doc.id || nextProps.doc.title !== this.props.doc.title || hasChangedRenderSetting(this.props.doc, nextProps.doc);
  }

  render() {
    const options = {
      lineNumbers: false,
      keyMap: 'sublime',
      viewportMargin: Infinity,
      lineWrapping: this.props.doc.settings?.wrap || false,
    };

    return (
      <CodeMirror
        value={this.props.doc.content}
        onChange={this.props.contentChanged}
        options={options}
      />
    );
  }
}
