import { Button } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo.js';


const TopBar = styled.div`
    position: fixed;
    background-color: #BF7E02;
    height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
`;

const SignUpLink = styled(Link)`
    margin-top: 40px;
    font-size: 18px;
    background: #ECAF3B;
    color: white;
    &:hover{
        background: #FDBB3E;
        color: white;
    }
    transition: all 0.2s;
    padding: 10px;
    border-radius: 3px;
`;

const SignInLink = styled(Link)`
    font-size: 14px;
    color: #fbf0e5;
    float: right;
    padding: 8px 10px;
    border-radius: 3px;
    &:hover {
        background: #ECAF3B;
        color: white;
    }
    transition: all 0.2s;
    margin-right: 7px;
    margin-top: 6px;
`;

const HeaderLogo = styled(Logo)`
    position: absolute;
    top: 9px;
    left: 10px;
    color: #fbf0e5;
`;

const TitleText = styled.h1`
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    color: rgb(32, 43, 51);
    border: rgb(32, 43, 51) 11px solid;
    height: 320px;
    width: 320px;
    padding-top: 116px;
`;

const Landing = () => {  
      return (
      <div>
        <TopBar>
            <HeaderLogo></HeaderLogo>
            <SignInLink to='/signin'>Sign in</SignInLink>
        </TopBar>
        <div style={{padding: '130px 20px 20px 20px'}}>
            <div style={{display: 'flex', flex: '0 0 auto', flexDirection: 'column', alignItems: 'center'}}>
                <TitleText>BigSquare</TitleText>
                <h2 style={{textAlign: 'center', fontWeight: 600, fontSize: '24px', color: 'rgb(32, 43, 51)'}}>All your notes, always.</h2>
                {/* <div style={{height: '300px', width: '800px', maxWidth: '100%', border: 'black 1px dashed'}}>Demo image</div>     */}
                <SignUpLink to='/signup'>SIGN UP</SignUpLink>
            </div>
        </div>



        




      </div>
    );
  };
  
  export default Landing;
  