export const getXCoordinateFromEvent = (event) => {
  if (event.changedTouches) {
    return event.changedTouches[0].clientX;
  }

  if (event.touches) {
    return event.touches[0].clientX;
  }

  return event.clientX;
}

export const getYCoordinateFromEvent = (event) => {
  if (event.changedTouches) {
    return event.changedTouches[0].clientY;
  }

  if (event.touches) {
    return event.touches[0].clientY;
  }

  return event.clientY;
}
