import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { FileAddOutlined, ExpandOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { signOut } from '../UserRepository.js';

const Wrapper = styled.div`
  position: fixed;
  height: 35px;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #BF7E02;
  z-index: 10;
`;

const LeftPanel = styled.div`
  position: absolute;
  left: 0px;
  padding: 4px;
`;

const RightPanel = styled.div`
  position: absolute;
  right: 0px;
  padding: 4px;
`;

const TopNavButton = styled.div`
    display: inline-block;    
    font-size: 18px;
    padding: 0px 5px;
    cursor: pointer;
    color: #eee;
    &:hover {
      background: #ECAF3B;
    }
    &:active {
      color: white;
    }
    transition: background 0.2s;
`;

const MenuTriggerWrapper = styled.div`
  position: absolute;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  top: 0px;
  right: 0px;
  color: #ccc;
  &:hover {
    color: #666;
  }
`;

const logout = () => {
  signOut().then(e => {
    window.location = '/'
  });
}

const menu = (
  <Menu>
    <Menu.Item>
      <Link to='/account/settings'>Settings</Link>
    </Menu.Item>
    
    <Menu.Divider />
    
    <Menu.Item>
      <a onClick={logout}>Logout</a>
    </Menu.Item>
  </Menu>
);


const TopNav = (props) => {
  return (
    <Wrapper>
      <LeftPanel>
        {/* <Tooltip title="New Note (CMD + K)"> */}
          {/* <Button icon={<PlusOutlined />} onClick={props.createNote} size="small"></Button> */}
          <TopNavButton onClick={props.createNote}><FileAddOutlined /></TopNavButton>
        {/* </Tooltip> */}
      </LeftPanel>
      <RightPanel>
        {/* <Tooltip title="Toggle Notes Map (CMD + P)"> */}
          
            <Dropdown overlay={menu} trigger={['click']}>
              <TopNavButton style={{marginRight: '10px'}}>
                <SettingOutlined />
              </TopNavButton>
            </Dropdown>
            

          <TopNavButton onClick={props.toggleMap}><ExpandOutlined /></TopNavButton>
        {/* </Tooltip> */}
      </RightPanel>
    </Wrapper>
  );
};

export default TopNav;
