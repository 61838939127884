import React, { useEffect, useState } from "react";
import SearchPanel from './search-panel/SearchPanel';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentsAndEditHistory, docCreated, clearFilters } from './reducers/documents';
import { createDocumentFromCoords } from './DocumentRepository';
import { getAutoDocPosition } from './services/docPositions';
import EditorPanel from "./components/EditorPanel";
import TopNav from "./components/TopNav";
import { Redirect } from "react-router";
import { hasLocalKey } from "./services/crypto";

const SHIFT_TRIGGER_DELAY_MS = 300; 

const isShift = key => key === 'Shift';

const isEscape = key => key === 'Escape';

const searchTriggerActivated = (now, lastShiftPress) => {
  return now - lastShiftPress <= SHIFT_TRIGGER_DELAY_MS;
}

const isNewDocTriggered = e => {
  return (e.key === 'k' && e.metaKey) ||
    (e.key === 'n' && e.metaKey);
}

const isSearchTriggered = e => {
  return e.key === 'p' && e.metaKey;
}

let lastShiftPress = 0;


export default function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.user;
  });

  const [status, allDocs] = useSelector(state => {
    return [
      state.documents.status,
      Object.values(state.documents.documents)
    ]  
  });

  const [isSearchVisible, setSearchVisible] = useState(true);

  const toggleSearchPanel = (visible) => {
    if (!visible) {
      dispatch(clearFilters());
    }
    setSearchVisible(visible);
  };

  const handleKeyDown = (e) => {
    const now = Date.now();

    if (isSearchVisible && isEscape(e.key)) {
      return toggleSearchPanel(false);
    }

    // if (e.keyCode === 85 && e.metaKey) { // U key
    //   console.log(e)
    //   e.cancelBubble = true;
    //   e.preventDefault();
    //   return setSearchVisible(true);
    // }

    if (isNewDocTriggered(e)) {
      const { left, top } = getAutoDocPosition(allDocs);
      const newDoc = createDocumentFromCoords(left, top);
      dispatch(docCreated(newDoc));
      window.scrollTo(0, 0);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (isSearchTriggered(e)) {
      lastShiftPress = 0;
      e.preventDefault();
      return toggleSearchPanel(!isSearchVisible);
    }
  

    if (!isShift(e.key)) {
      return;
    }

    if (searchTriggerActivated(now, lastShiftPress)) {
      lastShiftPress = 0;
      return toggleSearchPanel(!isSearchVisible);
    }

    lastShiftPress = now;
  }

  useEffect(() => {
    if (status === 'idle' && user) {
      dispatch(fetchDocumentsAndEditHistory());
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!user || !hasLocalKey() ) return (<Redirect to="/signin" />);

  if (status !== 'succeeded') return (<p style={{padding: '20px'}}>Loading notes...</p>);

  const editor = (
    <div className="App" style={{width: '100%', paddingTop: '35px'}}>
      <TopNav
        toggleMap={() => toggleSearchPanel(!isSearchVisible)}
        createNote={() => {
          const { left, top } = getAutoDocPosition(allDocs);
          const newDoc = createDocumentFromCoords(left, top);
          dispatch(docCreated(newDoc));
          window.scrollTo(0,0);
        }}
      />
      <div style={{
          // width: isSearchVisible ? '50%' : '100%'
          width: '100%'
        }}>
          <EditorPanel isSearchVisible={isSearchVisible} />
        </div>
      { isSearchVisible && <SearchPanel />}
    </div>
  );

  return editor;
}
