import React, { useState, useEffect } from 'react';
import { setUser } from '../reducers/user';
import { checkSignInStatus as signInStatus, signIn } from '../UserRepository';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import SignInWrapper from '../components/SignInWrapper';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import { hasLocalKey, storeEncryptedKeyLocally } from '../services/crypto';

const FormRow = styled.div`
  margin-top: 10px;
`;

const SignInButtonStyled = styled.button`
  text-align: center;
  min-width: 100px;
  cursor: pointer;
`;

const signInStates = {
  checking: 'checking',
  signingin: 'signingin',
  signinfailed: 'signinfailed',
  idle: 'idle',
  error: 'error',
  addressnotverified: 'addressnotverified'
};

const SignInButton = ({submit, signInState}) => {
  const label = signInState === signInStates.signingin ? <LoadingOutlined /> : 'Sign in';
  const disabled = signInState === signInStates.signingin;
  
  return <SignInButtonStyled disabled={disabled} onClick={submit}>{label}</SignInButtonStyled>;
}

const SignIn = () => {
  
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [signinStatus, setSigninStatus] = useState(signInStates.checking);
  const user = useSelector(state => {
    return state.user;
  });
  const dispatch = useDispatch();

  const checkCurrentSignInStatus = () => {
      signInStatus()
        .then(user => dispatch(setUser(user)))
        .catch(() => {
          setSigninStatus(signInStates.idle)
        });
  }

  useEffect(() => {
    if (hasLocalKey()) {
      checkCurrentSignInStatus();
    }
  }, []);

  const submitSignIn = () => {
    setSigninStatus(signInStates.signingin);

    signIn(signInEmail, signInPassword)
      .then(user => {
        if (user.code) {
          throw user;
        }
        storeEncryptedKeyLocally(user.encryptedKey, signInPassword);
        dispatch(setUser(user));
        setSigninStatus(signInStates.signedin);
      })
      .catch((e) => {
        if (e.code === 'ADDRESS_NOT_VERIFIED') {
          setSigninStatus(signInStates.addressnotverified);
        } else {
          setSigninStatus(signInStates.error);
        }
        
      });
  };

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      submitSignIn();
    }
  };

  if (signinStatus === signInStates.signedin) {
    return (<Redirect to="/app" />)
  }

  if (user) return (<Redirect to="/app" />);

  return (
    <div>
        <Link to="/" style={{position: 'absolute', top: '10px', left: '10px'}}><Logo /></Link>
        <SignInWrapper>
          <h2>Sign in</h2>
          <div><input style={{borderRadius: '5px', border: '1px solid #e6e6e6', padding: '12px 16px'}} placeholder='Email address' onKeyPress={checkSubmit} name="username" id="username" autoComplete="on" type="text" value={signInEmail} onChange={event => setSignInEmail(event.target.value)} /></div>
          <div><input style={{marginTop: '10px', borderRadius: '5px', border: '1px solid #e6e6e6', padding: '12px 16px'}} placeholder='Password' onKeyPress={checkSubmit} name="password" id="password" autoComplete="on" type="password" value={signInPassword} onChange={event => setSignInPassword(event.target.value)} /></div>
          <FormRow><SignInButton disabled={!signInEmail || !signInPassword} submit={submitSignIn} /></FormRow>
          {signinStatus === signInStates.error && <FormRow><p>Either the username or password are not correct. Please try again.</p></FormRow>}
          {signinStatus === signInStates.addressnotverified && <FormRow><p>Email address is not verified. Please check your email inbox for the verification link from no-reply@bigsquare.app.</p></FormRow>}
          
          <div style={{borderTop: 'gray 1px solid', marginTop: '40px'}}>
            <p style={{marginTop: '10px', marginBottom: '0px'}}>Don't have an account?</p>
            <Link to='/signup'>Create account</Link>
          </div>
        </SignInWrapper>
    </div>
  );
};

export default SignIn;
