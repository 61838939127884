import CryptoJS from 'crypto-js';
import uuidv4 from 'uuid/v4';

const localStorageKey = 'tsk';

function generateKey() {
    return `${uuidv4().replace(/-/g, '')}${uuidv4().replace(/-/g, '')}`;
}

export function generateKeyAndEncryptWithPasswordAndStore(password) {
    const key = generateKey();
    const encryptedKey = CryptoJS.AES.encrypt(key, password).toString();
    storeKeyLocally(key);
    return encryptedKey;
}

function storeKeyLocally(key) {
    localStorage.setItem(localStorageKey, key);
}

function getRawKey() {
    return localStorage.getItem(localStorageKey);
}

export function reEncryptKeyWithNewPassword(newPassword) {
    const key = getRawKey();
    const encryptedKey = CryptoJS.AES.encrypt(key, newPassword).toString();
    return encryptedKey;
}

export function clearLocalKey() {
    localStorage.removeItem(localStorageKey);
}

export function storeEncryptedKeyLocally(encryptedKey, password) {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedKey, password);
    const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);
    storeKeyLocally(decryptedKey);
}

export function hasLocalKey() {
    return !!localStorage.getItem(localStorageKey);
}

export function encryptString(string) {
    const key = getRawKey();
    return CryptoJS.AES.encrypt(string, key).toString();
}

export function decryptString(encryptedString) {
    const key = getRawKey();
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, key);
    const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedString;
}
