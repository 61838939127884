import React from 'react';
import styled from 'styled-components';
import EventRecord from './EventRecord';

const FocusedHistoryContainer = styled.div`
    display: block;
    height: 30px;
    width: 100%;
    border: black 1px solid;
    position: relative;
`;

const FocusedHistoryTimeline = ({ editHistory }) => {
    console.log(editHistory)
    return (
        <FocusedHistoryContainer>
            {editHistory.map((edit, index) => {
                const style={
                    left: `${edit.startPercent}%`,
                };

                return <EventRecord key={index} style={style} />
            })}
        </FocusedHistoryContainer>
    );
}

export default FocusedHistoryTimeline;
