import React from 'react';
import SignInWrapper from '../components/SignInWrapper';

const PostSignUp = () => {
    return (
        <SignInWrapper>
            <h1>Check your email</h1>
            <p>Almost there. Please check your email for a link to verify your email address.</p>
        </SignInWrapper>
    );
};

export default PostSignUp;
