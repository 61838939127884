import styled from 'styled-components';

const Header = styled.h2`
    font-weight: 900;
    font-size: 18px;
    color: rgb(32, 43, 51);
`;

const Logo = (props) => {
    return (
        <Header {...props}>BigSquare</Header>
    );
};

export default Logo;
